angular.module('FmsApp').controller("FarmProfileListController",
    function ($rootScope, $scope, FarmProfileFactory, NgTableParams, $mdDialog, $khMessage, $filter, GenericUtilsService) {

        $rootScope.page_id = "farm";
        $rootScope.sub_app_page_id = "farm_profile";
        $rootScope.currentNavItem = "profile";

        $scope.showEmptyState = false;
        function loadProfiles() {
            FarmProfileFactory.query(function (result) {
                $scope.all_farm_profiles = result;
                $scope.showEmptyState = (result.length == 0);
                $scope.profilesTable = new NgTableParams({}, {dataset: $scope.all_farm_profiles});
            }, function (error) {
                $khMessage.show($filter('translate')('default_error_message'));
            });
        }

        loadProfiles();

        $scope.editFarmProfile = function (slug) {
            $mdDialog.show({
                templateUrl: '/static/assets/khapps/fms_app/partials/farmdetails/profile/add_edit_farm_profile.html',
                controller: 'AddEditFarmProfileController',
                scope: $scope,
                preserveScope: true,
                locals: {
                    farm_slug: slug
                }
            }).then(function (result) {
                $scope.all_farm_profiles = GenericUtilsService.updateListAfterEdit($scope.all_farm_profiles, "farm_slug", slug, result);
                $scope.profilesTable = new NgTableParams({}, {dataset: $scope.all_farm_profiles});
            }, function () {
            });
        };

        $scope.farmProfilesListCard = [{
            card_title: $filter('translate')('farmDetails.farm_profiles'),
            kh_card_content_template: '/static/assets/khapps/fms_app/partials/farmdetails/profile/farm_profile_list.html',
            card_flex: 100,
            show_progress_bar: true
        }];

        $scope.$on("farmProfileAdded", function (boradcast, response) {
            if (response.farm_slug) {
                $scope.all_farm_profiles.unshift(response);
                $scope.profilesTable = new NgTableParams({}, {dataset: $scope.all_farm_profiles});
                $scope.showEmptyState = false;
            }
        });

        $scope.$on("team_changed", function (boradcast, response) {
            loadProfiles();
        });

        $scope.filterByFarm = {
            farm: {
                id: 'text',
                placeholder: $filter('translate')('farmDetails.filter_by_farm')
            }
        };

        $scope.filterByFarmManager = {
            farm_manager: {
                id: 'text',
                placeholder: $filter('translate')('farmDetails.filter_by_farm_manager')
            }
        };

        $scope.filterByOperationalSince = {
            operational_since: {
                id: 'text',
                placeholder: $filter('translate')('farmDetails.filter_by_operational_since')
            }
        };

        $scope.filterByCertificate = {
            certificates: {
                id: 'text',
                placeholder: $filter('translate')('farmDetails.filter_by_certificate')
            }
        };

    });
