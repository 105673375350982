angular.module('FmsApp').factory("WaterSourceFactory", ["$resource", function ($resource) {
    return $resource('api/v1.0/watersource/data/:source_slug/', {source_slug: '@source_slug'},
        {
            save: {
                method: 'POST'
            },
            update: {
                method: 'PUT'
            },
            get: {
                method: 'GET'
            },
             query: {
                method: 'GET',
                isArray: true
            }
        });
}]);
angular.module('FmsApp').factory("TypesOfWaterSourceFactory", ["$resource", function ($resource) {
    return $resource('api/v1.0/watersource/types/');
}]);