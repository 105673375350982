angular.module('FmsApp')
        .factory('V2GenericSeedProductFactory', function($resource) {
    return $resource('api/v2.0/generic-product/seed/:product_slug/', {}, {
        query: {
            isArray: false,
            cache: true
        },
        create: {
            method: 'POST'
        },
        get: {
            method: 'GET'
        },
        update: {
            method: 'PUT'
        }
    });
});