angular.module('FmsApp').factory("SoilSampleListFactory", ["$resource", function ($resource) {
    return $resource('api/v1.0/khutils/soilsamplelist/');
}]);
angular.module('FmsApp').factory("SoilTestFactory", ["$resource", function ($resource) {
    return $resource('api/v1.0/soilanalysis/test/:soil_test_slug/', {soil_test_slug: '@soil_test_slug'},
        {
            save: {
                method: 'POST'
            },
            update: {
                method: 'PUT'
            },
            get: {
                method: 'GET'
            }
        });
}]);
angular.module('FmsApp').factory("LegacySoilSampleFactory", ["$resource", function ($resource) {
    return $resource('/api/v1.0/soilanalysis/sample/:soil_sample_slug/', {soil_sample_slug: '@soil_sample_slug'}, {
        save: {
            method: 'POST'
        },
        update: {
            method: 'PUT'
        },
        query: {
            isArray: false
        }
    });
}]);
