angular.module('FmsApp').factory("MonitoringCategoriesFactory", ["$resource", function ($resource) {
    return $resource('api/v1.0/crop-monitoring/monitoring-categories/')
}]);
angular.module('FmsApp').factory("ObservationVariablesFactory", ["$resource", function ($resource) {
    return $resource('api/v1.0/crop-monitoring/observation-variables/')
}]);
angular.module('FmsApp').factory("VariableTypeFromVariableFactory", ["$resource", function ($resource) {
    return $resource('/api/v1.0/crop-monitoring/variable-type/', {variable: '@variable'}, {
        get: {
            method: 'GET',
            isArray: false
        }
    })
}]);
angular.module('FmsApp').factory("ImpactTypesFactory", ["$resource", function ($resource) {
    return $resource('api/v1.0/crop-monitoring/impact-types/')
}]);
