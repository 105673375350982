(function (angular) {
    "use strict";
    angular.module('FmsApp')
        .controller('CropVarietyDetailController', [
            "$scope", "variety_slug", "$filter", "CropVarietyFactory",
            CropVarietyDetailController
        ]);

    function CropVarietyDetailController($scope, variety_slug, $filter, CropVarietyFactory) {
        $scope.dialogTitle = $filter('translate')('farmSetup.crop_variety');
        CropVarietyFactory.get({variety_slug: variety_slug}, function (response) {
            $scope.variety = response.data;
        })
    }
})(angular);
