angular.module('FmsApp').config(
    function ($routeProvider, $resourceProvider, $translateProvider) {

        $resourceProvider.defaults.stripTrailingSlashes = false;
        $routeProvider

            .when("/myfarm/map/:type?/:slug?", {
                template: "<myfarm-map></myfarm-map>",
                resolve: {
                    // ensure that we have the platform settings for the default zoom before we render leaflet
                    platformSettings: function(PlatformSettingsService) {
                        return PlatformSettingsService.promise;
                    }
                }
            })
            .when("/myfarm/plot/inventory", {
                template: "<field-inventory-with-summary layout='column' flex></field-inventory-with-summary>"
            })
            .when("/myfarm/plot/inventory/admin", {
                template: "<field-inventory-admin></field-inventory-admin>"
            })
            .when("/myfarm/plot/inventory/:plotId", {
                template: "<field-inventory-with-summary layout='column' flex></field-inventory-with-summary>"
            })
            .when('/admin/slow-queries-ts', {
                template: '<slow-queries-chart  layout="column" flex></slow-queries-chart>'
            })
            .when('/admin/slow-queries-list', {
                template: '<slow-queries-list layout="column" flex></slow-queries-list>'
            })
            .when("/diary/", {
                controller: "ViewCropMonitoringController",
                templateUrl: "/static/assets/khapps/fms_app/partials/farmfielddiary/diary/view_crop_monitoring_card.html"
            })
            .when("/diary/:slug", {
                controller: "ViewCropMonitoringController",
                templateUrl: "/static/assets/khapps/fms_app/partials/farmfielddiary/diary/view_crop_monitoring_card.html"
            })
            .when("/event/", {
                controller: "InseasonEventListController",
                templateUrl: "/static/assets/khapps/fms_app/partials/farmfielddiary/event/inseason_event_list_card.html"
            })
            .when("/profile/", {
                controller: "FarmProfileListController",
                templateUrl: "/static/assets/khapps/fms_app/partials/farmdetails/profile/farm_profile_list_card.html"
            })
            .when("/certificate/", {
                controller: "FarmCertificateListController",
                templateUrl: "/static/assets/khapps/fms_app/partials/farmdetails/certificate/farm_certificate_list_card.html"
            })
            .when("/emergency-contact/", {
                controller: "EmergencyContactListController",
                templateUrl: "/static/assets/khapps/fms_app/partials/farmdetails/emergency_contact/emergency_contact_list_card.html"
            })
            .when("/equipment/", {
                controller: "FarmEquipmentListController",
                templateUrl: "/static/assets/khapps/fms_app/partials/farmdetails/equipment/farm_equipment_list_card.html"
            })
            .when("/water-source/", {
                controller: "WaterSourceListController",
                templateUrl: "/static/assets/khapps/fms_app/partials/farmdetails/water_source/water_source_list_card.html"
            })
            .when("/planting-progress/", {
                controller: "ProgressController",
                templateUrl: "/static/assets/khapps/fms_app/partials/farmreports/planting_progress_card.html"
            })
            .when("/inseason-progress/", {
                controller: "ProgressController",
                templateUrl: "/static/assets/khapps/fms_app/partials/farmreports/inseason_card.html"
            })
            .when("/soil-results/", {
                template: '<soil-report-table></soil-report-table>'
            })
            .when("/lab/", {
                controller: "SoilAnalysisLabController",
                templateUrl: '/static/assets/khapps/fms_app/partials/soilanalysis/soilanalysis_landing.html'
            })
            .when("/sharing/", {
                controller: "SharingController",
                templateUrl: '/static/assets/khapps/fms_app/partials/manage/sharing/sharing_landing.html'
            })
            .when("/crop-variety/", {
                controller: "CropVarietyController",
                templateUrl: '/static/assets/khapps/fms_app/partials/setup/variety/crop_variety_list_card.html'
            })
            .when("/seed/", {
                controller: "SeedController",
                templateUrl: '/static/assets/khapps/fms_app/partials/setup/seed/seed_list_card.html'
            })
            .when("/charts/", {
                controller: "ProgressController",
                templateUrl: "/static/assets/khapps/fms_app/partials/farmreports/planting_progress_card.html"
            })
            .otherwise({
                redirectTo: '/myfarm/plot/inventory'
            });


        // Translations

        var TranslateHelpers = {
            getTranslateDict: function (singname, pluname) {
                return {
                    card: {
                        title: singname
                    },
                    tableCard: {
                        title: pluname
                    },
                    form: {
                        title: singname + " Add/Edit"
                    },
                    report: {
                        title: singname + " Summary"
                    },
                    message: TranslateHelpers.getMessageDict(singname)

                };
            },
            getMessageDict: function (arg) {
                return {
                    save: {
                        success: "Saved successfully.",
                        error: "Failed to save",
                        info: "Saving " + arg + "... "
                    },
                    update: {
                        success: "Updated successfully.",
                        error: "Failed to update",
                        info: "Updating " + arg + "... "
                    },
                    remove: {
                        warning: "Are you sure you want to delete " + arg + " :",
                        success: arg + " has been deleted successfully.",
                        error: arg + " has not been deleted.",
                        info: "Deleting " + arg + "... "
                    },
                    duplicate: {
                        success: arg + " has been created successfully.",
                        error: arg + " has not been created.",
                        info: "Creating " + arg + "... "
                    }
                };
            }
        };

        var commonEN = function () {
            return {
                actions: "Action",
                edit: "Edit",
                view: "View",
                pleaseWaitSaving: "Please wait - saving changes...",
                filterBy: "Filter by ",
                export_to_csv: 'Export to csv',
                configColumns: 'Config columns',
                create_success: 'Successfully created',
                create_failed: 'Failed creation',
                update_success: 'Successfully updated',
                update_failed: 'Failed update',
                export_as: 'Export as',
                are_you_sure_to_delete: 'Are you sure to delete?',
                delete_field_crop_confirmation: 'Deleting field crop - are you sure?',
                are_you_sure_to_delete_farm: 'Deleting the farm will automatically delete its associated fields and crops. Are you sure to delete?',
                are_you_sure_to_delete_field: 'Deleting the field will automatically delete its associated crops. Are you sure to delete?',
                delete: 'Delete',
                csv: 'CSV',
                table_view: 'Table View',
                map_view: 'Map View',
                growing_season: 'Growing season',
                select_growing_season: 'Select growing season',
                select_farm: 'Select farm',
                select_field: 'Select field',
                myfarm: 'MyFarm',
                import_gatekeeper_field_operation: 'Import GateKeeper Field Operation',
                planting_season: 'Planting season',
                import_operations: 'Import operations',
                import_field_boundary: 'Import Field Boundary',
                column_containing_field_name: 'Column Containing Field Name',
                representative_soil_type: 'Representative Soil Type',
                import_fields: 'Import Fields',
                import_field_uav_data: 'Import field UAV data',
                import_data: 'Import Data',
                upload_data: 'Upload Data',
                upload_file: 'Upload file',
                add_field: 'Add field',
                edit_field: 'Edit field',
                add_store: 'Add Store',
                edit_store: 'Edit Store',
                add_farm: 'Add farm',
                edit_farm: 'Edit farm',
                add_crop: 'Add crop',
                edit_crop: 'Edit crop',
                save: 'Save',
                cancel: 'Cancel',
                yes: 'Yes',
                no: 'No',
                field_is_required: 'This field is required'
            }
        };

        var SOIL_RESULT_EN = "Result";
        var SOIL_RESULT_PLU_EN = "Results";

        var soilResultDictEN = function () {
            var dict = TranslateHelpers.getTranslateDict(SOIL_RESULT_EN, SOIL_RESULT_PLU_EN);
            dict.action = "Action";
            dict.sample_name = "Sample Name";
            dict.sampling_date = "Sample Date";
            dict.sample_depth_value = "Sample Depth";
            dict.farm_field = "Sample Field";
            dict.lab = "Lab";
            dict.add_new_row = "Add New Row";
            dict.key = "Key";
            dict.value = "Value";
            dict.unit = "Unit";
            dict.base = "Base";
            dict.physical_properties = "Physical Properties";
            dict.bulk_density = "Bulk density";
            dict.sand = "Sand";
            dict.silt = "Silt";
            dict.clay = "Clay";
            dict.texture = "Texture";
            dict.colour = "Colour";
            dict.structure = "Structure";
            dict.plastic_limit = "Plactic limit";
            dict.field_capacity = "Field capacity";
            dict.permanent_wilting_point = "Permanent wilting point";
            dict.percolation_rate = "Percolation rate";
            dict.hydraulic_conductivity = "Hydraulic conductivity";
            dict.moisture_content = "Moisture content";
            dict.chemical_properties = "Chemical Properties";
            dict.macro_nutrients = "Macro Nutrients";
            dict.micro_nutrients = "Micro Nutrients";
            dict.soil_test_result = "Soil Test Result";
            dict.other_properties = "Other properties";
            dict.notes_and_documents = "Notes and documents";
            return dict
        };


        var SOIL_LAB_EN = "Result";
        var SOIL_LAB_PLU_EN = "Results";

        var soilLabDictEN = function () {
            var dict = TranslateHelpers.getTranslateDict(SOIL_LAB_EN, SOIL_LAB_PLU_EN);
            dict.action = "Action";
            dict.laboratory_name = "Name";
            dict.type = "Type";
            dict.phone = "Phone";
            dict.email = "Email";
            dict.website = "Website";
            dict.address = "Address";
            return dict
        };

        var FIELD_INVENTORY_EN = "Field Inventory";
        var FIELD_INVENTORY_PLU_EN = "Field Inventory";

        var fieldInventoryDictEN = function () {
            var dict = TranslateHelpers.getTranslateDict(FIELD_INVENTORY_EN, FIELD_INVENTORY_PLU_EN);
            dict.action = "Action";
            dict.team_name = "Team";
            dict.farm_name = "Farm";
            dict.field_name = "Field";
            dict.plot_name = "Plot";
            dict.crop = "Crop";
            dict.variety = "Variety";
            dict.location_lat_long = "Location (lat/long)";
            dict.cropped_area_ha = "Cropped Area (ha)";
            dict.actual_planting_date = "Actual Planting Date";
            dict.expected_planting_date = "Expected Planting Date";
            dict.actual_harvest_date = "Actual Harvest Date";
            dict.expected_harvest_date = "Expected Harvest Date";
            dict.expected_harvest_week = "Expected Harvest Week";
            dict.estimated_yield_t_per_ha = "Est. Yield (t/ha)";
            dict.actual_yield_t_per_ha = "Actual Yield (t/ha)";
            dict.total_tonnage_tonnes = "Total Tonnage (tonnes)";
            dict.market = "Market";
            dict.destination = "Destination";
            dict.customer = "Customer";
            dict.customers = "Customers";
            dict.alert_status = "Alert Status";
            dict.harvest_complete = "Harvest Complete";
            dict.update = "Update";
            dict.no_of_growers = "Number of growers";
            dict.no_of_farms = "Number of farms";
            dict.no_of_fields = "Number of fields";
            dict.total_workable_area = "Total workable area (ha)";
            dict.no_of_plots = "Number of plots";
            dict.cropped_area = "Cropped area (ha)";
            dict.crops_cultivated = "Crops cultivated";
            dict.all_fields = "All Fields";
            dict.field_inventory = "Field Inventory";
            dict.crop_inventory = "Crop Inventory";
            dict.alert = "Alert";
            dict.no_alert = "No alert";
            dict.of = "of";
            dict.No = "No";
            dict.Yes = "Yes";
            dict.page_size = "Page size";
            dict.variety_is_required = "Please select a variety";
            dict.greater_than_zero = "Please enter a value greater than zero";
            dict.estimated = "Estimated";
            dict.harvest_date = "Harvest date";
            dict.planting_date = "Planting date";
            dict.yield = "Yield (t/ha)";
            dict.actual = "Actual";
            dict.planting = "Planting";
            dict.harvesting = "Harvesting";
            dict.fullscreen = "Fullscreen";
            dict.fullscreen_exit = "Exit fullscreen";
            dict.filter_item_all = "All";
            dict.plot_filter = "Plot filter";
            dict.crop_filter = "Crop filter";
            dict.variety_filter = "Variety filter";
            dict.estimated_total_tonnage = 'Est. Total Tonnage';
            dict.harvested = 'Harvested';
            dict.planted = 'Planted';
            dict.total_cropped_area = 'Total cropped area';


            return dict;
        };

        var SHARING_INVENTORY_EN = "Sharing Inventory";
        var SHARING_INVENTORY_PLU_EN = "Sharing Inventory";

        var sharingInventoryDictEN = function () {
            var dict = TranslateHelpers.getTranslateDict(SHARING_INVENTORY_EN, SHARING_INVENTORY_PLU_EN);
            dict.action = "Action";
            dict.team_name = "Team Name";
            dict.field_name = "Field Name";
            dict.service_name = "Service Name";
            dict.start_date = "Start Date";
            dict.end_date = "End Date";
            dict.duration = "Duration (months)";
            dict.object_type = "Object Type";
            dict.object_name = "Object Name";
            dict.shared_with = "Shared With";
            dict.shared_permission = "Shared Permission";
            dict.shared_status = "Shared Status";
            return dict;
        };


        $translateProvider.translations('en', {
            common: commonEN(),
            fieldInventory: fieldInventoryDictEN(),
            sharingInventory: sharingInventoryDictEN(),
            map: {
                slug: "Unique ID",
                field: 'Field',
                edit_soil_sample: 'Edit soil sample',
                sample_details: 'Sample details',
                name: 'Name',
                field_name: 'Field name',
                date: 'Date',
                depth: 'Depth',
                test_results: 'Test results',
                result: 'Result',
                lab: 'Lab',
                farm: 'Farm',
                edit_field_plot_crop: 'Edit field plot crop',
                share: 'Share',
                subscribe_to_satellite_services: 'Subscribe to satellite services',
                notification: 'Notification',
                crop_details: 'Crop details',
                crop: 'Crop',
                variety: 'Variety',
                expected_sowing_date: 'Expected sowing date',
                soil_type: 'Soil type',
                cropped_area_ha: 'Cropped area (ha)',
                drawn_area_ha: 'Drawn area (ha)',
                operations: 'Operations',
                diary: 'Diary',
                satellite_service: 'Aerial Monitoring Service',
                satellite_information_guide: 'Satellite and UAV based field monitoring, analysis and insights',
                layers_and_legend: 'Layers and Legend',
                information_guide: 'Information guide on layers, legend and use of mapping',
                field_information: 'Field Information',
                edit_field: 'Edit field',
                show_headland: 'Show headland',
                show_tramline: 'Show tramline',
                field_details: 'Field details',
                workable_area_ha: 'Workable area (ha)',
                field_area_ha: 'Field area (ha)',
                lat_long: 'Lat/long',
                field_group: 'Field group',
                edit_farm: 'Edit farm',
                location: 'Location',
                farm_details: 'Farm details',
                time: 'Time',
                day: 'Day'
            },
            soilResult: soilResultDictEN(),
            soilLab: soilLabDictEN(),
            operation: {
                info: 'Info',
                filter_by_info: 'Filter by info',
                operation_type: 'Operation Type',
                field_or_plot: 'Field / Plot',
                date: 'Date',
                status: 'Status',
                operation_is_overdue: 'Operation is overdue. ',
                operation_is_planned: 'Operation is planned.',
                operation_is_completed: 'Operation is completed. ',
                click_to_mark_it_completed: 'Click to mark it completed.',
                click_to_mark_it_pending: 'Click to mark it pending.',
                duplicate: 'Duplicate',
                add_operation: 'Add Operation',
                step_1: 'Step 1',
                step_2: 'Step 2',
                step_3: 'Step 3',
                plot_details: 'Plot Details',
                duplicate_operation: 'Duplicate Operation',
                edit_operation: 'Edit Operation',
                change_operation_status: 'Change Operation Status',
                are_you_sure_to_mark_this_operation_as_pending: "Are you sure to mark this operation as pending?",
                are_you_sure_to_mark_this_operation_as_completed: "Are you sure to mark this operation as completed?",
                plot: 'Plot',
                operation: 'Operation',
                products: 'Products',
                depth: 'Depth',
                yield: 'Yield',
                type: 'Type',
                register_new_water_source: 'Register New Water Source',
                add_cost: 'Add Cost',
                remove_cost: 'Remove Cost',
                add_equipment_for_operation: 'Add Equipment for Operation',
                register_new_equipment: 'Register New Equipment',
                duplicate_equipment: 'Duplicate Equipment',
                remove_equipment: 'Remove Equipment',
                provide_only_common_details: 'Please provide details that are common for all the fields/plots chosen. If you want to add details specific to one of those, you can edit the particular instance later.',
                equipment_details: 'Equipment Details',
                cost_details: 'Cost Details',
                more_details: 'More Details',
                add_new_product: 'Register New Product',
                add_new_water_source: 'Register New Water Source',
                show_products: 'Show Products',
                hide_products: 'Hide Products',
                add_product: 'Add Product',
                product_type: 'Product type',
                product: 'Product',
                batch: 'Batch',
                rate_per_hectare: 'Rate (per ha)',
                unit: 'Unit',
                unit_cost: 'Unit Cost',
                concentration: 'Concentration',
                spray_quality: 'Spray Quality',
                duplicate_product: 'Duplicate Product',
                remove_product: 'Remove Product',
                split_operation_over_another_day: 'Split operation over another day',
                copy_operation_detail: 'Copy Operation Detail',
                paste_operation_detail: 'Paste Operation Detail',
                remove_operation_detail: 'Remove Operation Detail',
                start_date_and_time: 'Start Date and Time',
                duration_in_hours: 'Duration (hours)',
                percent_completed: '% Area Completed',
                done_by: 'Done by',
                planting_operation_for: 'Planting operation for ',
                burn_down_operation_for: 'Burn down operation for ',
                already_exists: ' already exists.',
                are_you_sure_to_delete_this_operation: 'Are you sure to delete this operation?',
                delete_operation: 'Delete Operation',
                operations: 'Operations',
                filter_by_operation_type: 'Filter by operation type',
                filter_by_plot: 'Filter by plot',
                filter_by_date: 'Filter by date',
                filter_by_status: 'Filter by status',
                filter_by_recommended_by: 'Filter by recommended by',
                filter_by_assigned_to: 'Filter by assigned to / done by',
                back: 'Back',
                next: 'Next',
                start_date: 'Start Date',
                start_time: 'Start Time',
                remove_operation: 'Remove Operation',
                attachments: 'Attachments',
                equipment_used: 'Equipment used',
                equipment: 'Equipment',
                operational_hours: 'Operational hours',
                cost_per_hour: 'Equipment Cost per hour',
                fuel_consumption: 'Fuel consumption',
                fuel_cost: 'Fuel cost',
                total_cost: 'Total cost',
                cost_of_operation: 'Other costs',
                item: 'Item',
                cost_per_ha: 'Cost per hectare',
                growth_stage: 'Growth stage',
                recommended_by: 'Recommended by',
                assigned_to: 'Assigned to / Done by',
                growing_season: 'Growing season',
                operational_area: 'Operational area',
                soil_condition: 'Soil condition',
                other: 'Other',
                planned_schedule: 'Planned schedule',
                done_on: 'Done on',
                start: 'Started at',
                operation_details: 'Details',
                planned: 'Planned',
                completed: 'Completed',
                schedule: 'Schedule',
                total_applied: 'Total applied',
                row_spacing: 'Row spacing',
                plant_spacing: 'Plant spacing',
                tillage_depth: 'Tillage depth',
                application_method: 'Application method',
                method_of_irrigation: 'Method of irrigation',
                source: 'Source',
                number_of_emitters: 'Number of emitters',
                emitter_capacity: 'Emitter capacity',
                wetting_area: 'Wetting area',
                reason: 'Reason',
                timing: 'Timing',
                details: 'Details',
                applied: 'Applied',
                total_quantity: 'Total quantity',
                water_volume: 'Water volume',
                product_rate_calculator: 'Product rate calculator',
                calculate_product_rate: 'Calculate product rate',
                result: 'Result'
            },
            diary: {
                diary: 'Diary',
                event: 'Event',
                remove_value: 'Remove Value',
                submit: 'Submit',
                farm: 'Farm',
                field: 'Field',
                plot: 'Plot',
                date: 'Date',
                back: 'Back',
                category: 'Category',
                all: 'All',
                description: 'Description',
                inseason_event: 'Inseason Event',
                impact: 'Impact',
                farm_field_plot: 'Farm / Field / Plot',
                summary_details: 'Summary Details',
                lot: 'Lot',
                step_1: 'Step 1',
                step_2: 'Step 2',
                step_3: 'Step 3',
                variable: 'Variable',
                values: 'Value(s)',
                done: 'Done',
                reset: 'Reset',
                crop_stage: 'Crop Stage',
                primary_growth_stage: 'Primary Growth Stage',
                secondary_growth_stage: 'Secondary Growth Stage',
                percent_field_cover: 'Percent Field Cover',
                general_impression: 'General Impression',
                disease_incidence: 'Disease Incidence',
                pest_damage: 'Pest Damage',
                weed_incidence: 'Weed Incidence',
                observations: 'Observations',
                attached_images: 'Attached Images',
                field_diary_entry: 'Diary Entry',
                attachments: 'Attachments',
                notification_status: 'Notification Status',
                notification_requested: 'Notification Requested',
                no_notification_requested: 'No Notification Requested',
                add_field_diary: 'Add Diary Entry',
                are_you_sure_to_delete_this_field_diary_entry: 'Are you sure to delete this diary entry?',
                are_you_sure_to_delete_this_image: 'Are you sure you want to delete this image?',
                delete_field_diary: 'Delete Diary Entry',
                delete_field_diary_image: 'Delete Diary Image',
                deleted_successfully: 'Deleted Successfully',
                field_diary_entries: 'Diary Entries',
                edit_field_diary: 'Edit Diary Entry',
                edit_inseason_event: 'Edit Inseason Event',
                add_inseason_event: 'Add Inseason Event',
                are_you_sure_to_delete_this_inseason_event: 'Are you sure to delete this inseason event?',
                delete_inseason_event: 'Delete Inseason Event',
                inseason_events: 'Inseason Events',
                filter_by_plot: 'Filter by plot',
                filter_by_date: 'Filter by date',
                filter_by_variable: 'Filter by variable',
                filter_by_name: 'Filter by name',
                filter_by_category: 'Filter by category',
                filter_by_farm_or_field_or_plot: 'Filter by farm / field / plot',
                add_observation: 'Add Observation',
                recorded_by: 'Recorded by',
                recorded_on: 'Recorded on'
            },
            farmDetails: {
                farm_certificates: 'Farm Certificates',
                description: 'Description',
                produce: 'Produce',
                achievements: 'Achievements',
                category: 'Category',
                more_info: 'More Info',
                operational_since: 'Operational since',
                location: 'Location',
                farm_manager: 'Farm Manager',
                connect: 'Connect',
                twitter: 'Twitter',
                share: 'Share',
                share_in_linkedin: 'Share in linkedin',
                share_in_twitter: 'Share in twitter',
                share_in_facebook: 'Share in facebook',
                latest_tweets: 'Latest Tweets',
                tweets_by: 'Tweets by',
                twitter_activity_unavailable: 'Twitter activity unavailable.',
                certificates: 'Certificates',
                certificate: 'Certificate',
                emergency_contact: 'Emergency Contact',
                profile: 'Profile',
                equipment: 'Equipment',
                water_source: 'Water Source',
                farm_profile: 'Farm Profile',
                edit_farm_profile: 'Edit Farm Profile',
                add_farm_profile: 'Add Farm Profile',
                logo: 'Logo',
                optimal_size_For_logo: 'Optimal size 150 px X 150 px',
                change: 'Change',
                picture: 'Picture',
                optimal_size_for_picture: 'Optimal size 800 px X 300 px',
                another_picture: 'Another Picture',
                optimal_size_for_another_picture: 'Optimal size 700 px X 200 px',
                add_new_certificate: 'Add New Certificate',
                type: 'Type',
                name: 'Name',
                farm: 'Farm',
                doctor: 'Doctor',
                hospital: 'Hospital',
                model: 'Model',
                registration_number: 'Registration Number',
                capacity: 'Capacity',
                power: 'Power',
                emergency_contacts: 'Emergency Contacts',
                health_and_safety_phone: 'Health and Safety Phone',
                veterinary_surgery: 'Veterinary Surgery',
                health_and_safety_dept: 'Health and Safety Dept.',
                environment_agency_or_gov_dept_phone: 'Environment Agency / Govt Dept Phone',
                general: 'General',
                incident: 'Incident',
                floodline: 'Floodline',
                pollution: 'Pollution',
                rural_payment_agency: 'Rural Payment Agency',
                utility_phone: 'Utility Phone',
                electricity_supplier: 'Electricity Supplier',
                gas_supplier: 'Gas Supplier',
                water_supplier: 'Water Supplier',
                farm_location: 'Farm Location',
                farm_directions: 'Farm Directions',
                utility_locations: 'Utility Locations',
                nearest_phone: 'Nearest Phone',
                fire_extinguishers: 'Fire Extinguishers',
                electricity_isolation: 'Electricity Isolation',
                gas_isolation: 'Gas Isolation',
                other_locations: 'Other Locations',
                water_supply: 'Water Supply',
                nearest_washing: 'Nearest Washing',
                flammable: 'Flammable',
                poisonous_or_noxious: 'Poisonous / Noxious',
                certification: 'Certification',
                certified_by: 'Certified by',
                certification_date: 'Certification Date',
                renewal_date: 'Renewal Date',
                expiry_date: 'Expiry Date',
                water_sources: 'Water Sources',
                edit_water_source: 'Edit Water Source',
                add_water_source: 'Add Water Source',
                farm_profiles: 'Farm Profiles',
                farm_equipment: 'Farm Equipment',
                add_farm_equipment: 'Add Farm Equipment',
                edit_farm_equipment: 'Edit Farm Equipment',
                filter_by_name: 'Filter by name',
                filter_by_model: 'Filter by model',
                filter_by_type: 'Filter by type',
                filter_by_registration_number: 'Filter by registration number',
                filter_by_farm: 'Filter by farm',
                filter_by_farm_manager: 'Filter by farm manager',
                filter_by_operational_since: 'Filter by operational since',
                filter_by_certification: 'Filter by certification',
                filter_by_date: 'Filter by date'
            },
            farmReports: {
                planting_progress: 'Planting Progress',
                farm: 'Farm',
                inseason_progress: 'Inseason Progress',
                crop_rotation: 'Crop Rotation',
                filter_by_field: 'Filter by field',
                filter_by_farm: 'Filter by farm',
                filter_by_crop: 'Filter by crop',
                crop: 'Crop',
                field: 'Field',
                recorded_value: "Recorded value",
                recorded_unit: "Recorded unit",
                recorded_date: "Recorded date",
                recorded_variable: "Recorded variable",
                plot_name: "Plot name",
                filter_by_variable: "Filter by variable",
                filter_by_plot_name: "Filter by plot name",
                filter_by_date: "Filter by date",
                filter_by_value: "Filter by value",
                filter_by_unit: "Filter by unit"
            },
            soilAnalysis: {
                soil_test_result: 'Soil Test Result',
                result: 'Result',
                results: 'Results',
                lab: 'Lab',
                no_labs: 'No soil analyis labs found',
                no_results: 'No soil analyis test results found',
                add_item: 'Creating',
                edit_item: 'Updating',
                create_success: 'Successfully created',
                create_failed: 'Failed creation',
                update_success: 'Successfully updated',
                update_failed: 'Failed update',
                physical_properties: 'Physical properties',
                chemical_properties: 'Chemical properties',
                micro_nutrients: 'Micro nutrients',
                macro_nutrients: 'Macro nutrients',
                other_properties: 'Other properties',
                notes_and_documents: 'Notes and documents',
                texture: 'Texture',
                sand: 'Sand (%)',
                silt: 'Silt (%)',
                clay: 'Clay (%)',
                colour: 'Colour',
                structure: 'Structure',
                bulk_density: 'Bulk density (g/cc)',
                moisture_content: 'Moisture content (%)',
                field_capacity: 'Field capacity (%)',
                permanent_wilting_point: 'Permanent wilting point (%)',
                percolation_rate: 'Percolation rate (cm/hr)',
                hydraulic_conductivity: 'Hydraulic conductivity (cm/day)',
                plastic_limit: 'Plastic limit (%)',
                ph: 'pH',
                electrical_conductivity: 'Electrical conductivity (microsiemens/cm)',
                cation_exchange_capacity: 'Cation exchange capacity (meq/100 gms)',
                sodium_adsorption_ratio: 'Sodium adsorption ratio',
                organic_matter: 'Organic matter (%)',
                organic_carbon: 'Organic carbon (%)',
                available_nitrogen: 'Available nitrogen (ppm)',
                available_phosphorus: 'Available phosphorus (ppm)',
                available_potassium: 'Available potassium (ppm)',
                magnesium: 'Magnesium (ppm)',
                calcium: 'Calcium (ppm)',
                sulphur: 'Sulphur (ppm)',
                iron: 'Iron (ppm)',
                molybdenum: 'Molybdenum (ppm)',
                boron: 'Boron (ppm)',
                copper: 'Copper (ppm)',
                manganese: 'Manganese (ppm)',
                sodium: 'Sodium (ppm)',
                zinc: 'Zinc (ppm)',
                nickel: 'Nickel (ppm)',
                chlorine: 'Chlorine (ppm)',
                cobalt: 'Cobalt (ppm)',
                aluminium: 'Aluminium (ppm)',
                silicon: 'Silicon (ppm)',
                key: 'Property',
                value: 'Value',
                unit: 'unit',
                attachments: 'Attachments'
            },
            farmSetup: {
                crop_variety: 'Crop variety',
                variety: 'Variety',
                crop: 'Crop',
                public: 'Public',
                team: 'Team',
                filter_by_variety: 'Filter by variety',
                filter_by_crop: 'Filter by crop',
                filter_by_team: 'Filter by team',
                filter_by_ownership: 'Filter by ownership',
                variety_is_public: 'Variety is public',
                variety_is_proprietary: 'Variety is proprietary',
                seed: 'Seed',
                ownership: 'Ownership',
                proprietary: 'Proprietary',
                description: 'Description',
                crop_duration: 'Crop duration',
                potential_yield: 'Potential yield',
                add_variety: 'Add crop variety',
                edit_variety: 'Edit crop variety',
                manufacturer: 'Manufacturer',
                proprietary_product: 'Proprietary product',
                public_product: 'Public product',
                filter_by_name: 'Filter by name',
                filter_by_manufacturer: 'Filter by manufacturer',
                type: 'Type',
                features: 'Features',
                add_seed: 'Add seed',
                edit_seed: 'Edit seed',
                product_name: 'Product name'
            },
            farmManage: {
                sharing: "Sharing",
            }
        });
    });
