angular.module('FmsApp')
    .controller('AddEditVarietyController', function AddEditVarietyController($rootScope,
                                                                              $scope,
                                                                              $filter,
                                                                              $khMessage,
                                                                              $mdDialog,
                                                                              KhutilsCropListFactory,
                                                                              PrimarySecondaryTeamOfUserFactory,
                                                                              CropVarietyFactory,
                                                                              variety_slug,
                                                                              crop_id, GenericUtilsService) {

        $scope.allow_public = $rootScope.is_superuser === 'True';
        $scope.dialogTitle = $filter('translate')('farmSetup.add_variety');

        function getTeamList(pageNo) {
            PrimarySecondaryTeamOfUserFactory.query({page: pageNo}, function (teams) {
                $scope.teams = $scope.teams.concat(teams.results);
                if (teams.next) {
                    getTeamList(pageNo + 1);
                } else {
                    KhutilsCropListFactory.query(function (crop_data) {
                        $scope.crop_list = crop_data;
                        $scope.cropVarietyForm = cropVarietyForm(crop_data, $scope.teams, crop_id, $scope.allow_public);
                    });
                }
            });
        }

        $scope.teams = [];
        getTeamList(1);

        if (variety_slug) {
            $scope.dialogTitle = $filter('translate')('farmSetup.edit_variety');
            CropVarietyFactory.get({variety_slug: variety_slug, content: 'edit'}, function (response) {
                $scope.cropVariety = response.data;
                $scope.cropVariety.allow_public = $scope.allow_public;
            });
        } else {
            var selected_team_slug = ($rootScope.selected_team !== 'All Teams') ? $rootScope.selected_team : null;
            $scope.dialogTitle = $filter('translate')('farmSetup.add_variety');
            $scope.cropVariety = {
                allow_public: $scope.allow_public,
                is_public: false,
                crop: crop_id,
                team_slug: selected_team_slug
            };
        }

        $scope.sendData = function () {
            if (variety_slug) {
                CropVarietyFactory.update({variety_slug: variety_slug}, $scope.cropVariety, function (response) {
                    $khMessage.show($filter('translate')('updated_successfully'));
                    $mdDialog.hide(response);
                }, function (error) {
                    GenericUtilsService.handleErrorV2($filter, $khMessage, error);
                });
            } else {
                CropVarietyFactory.create($scope.cropVariety, function (response) {
                    $khMessage.show($filter('translate')('created_successfully'));
                    $mdDialog.hide(response);
                }, function (error) {
                    GenericUtilsService.handleErrorV2($filter, $khMessage, error);
                });
            }
        };
        $scope.close = function () {
            $mdDialog.close();
        };


        var cropVarietyForm = function (crops, teams, disable_crop, allow_public) {
            return [{
                className: 'flex-100',
                type: 'checkbox',
                key: 'is_public',
                hide: !allow_public,
                templateOptions: {
                    label: 'Public'
                }
            }, {
                className: 'flex-100',
                type: 'input',
                key: 'variety_name',
                templateOptions: {
                    label: 'Variety name',
                    required: true
                }
            }, {
                key: 'crop',
                className: 'flex-100',
                type: 'searchable_select',
                templateOptions: {
                    valueProp: 'crop_id',
                    labelProp: 'crop_name',
                    label: 'Crop',
                    options: crops,
                    required: true,
                    disabled: disable_crop
                }
            }, {
                key: 'team_slug',
                className: 'flex-100',
                type: 'searchable_select',
                templateOptions: {
                    valueProp: 'team_slug',
                    labelProp: 'team_name',
                    label: 'Team',
                    options: teams,
                    required: true
                },
                expressionProperties: {
                    'templateOptions.disabled': 'model.variety_slug && !model.allow_public'
                }
            }, {
                className: 'flex-100',
                type: 'textarea',
                key: 'description',
                templateOptions: {
                    label: 'Description',
                    rows: 3
                }
            }, {
                className: 'flex-50',
                type: 'input',
                key: 'crop_duration.value',
                templateOptions: {
                    label: 'Crop duration',
                    type: 'number'
                },
                validators: {
                    integer: {
                        expression: function (viewValue, modelValue) {
                            if (modelValue) {
                                return ((modelValue % 1 === 0) && (modelValue >= 1));
                            } else {
                                return true;
                            }
                        },
                        message: '"Please provide a valid positive integer."'
                    }
                }
            }, {
                className: 'flex-50',
                type: 'input',
                key: 'crop_duration.unit',
                templateOptions: {
                    label: 'Unit'
                }
            }, {
                className: 'flex-50',
                type: 'input',
                key: 'potential_yield.value',
                templateOptions: {
                    type: 'number',
                    label: 'Potential yield'
                }
            }, {
                className: 'flex-50',
                type: 'input',
                key: 'potential_yield.unit',
                templateOptions: {
                    label: 'Unit'
                }
            }];
        };
    });

