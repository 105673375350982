(function(angular){
    "use strict";
    angular.module('FmsApp')
        .factory('CropVarietyFactory', CropVarietyFactory);

    function CropVarietyFactory($resource){
        return $resource('api/v2.0/dms/crop-variety/:variety_slug/', {}, {
            query: {
                isArray: false
            },
            create: {
                method: 'POST'
            },
            get: {
                method: 'GET'
            },
            update: {
                method: 'PUT'
            }
        });
    }

})(angular);