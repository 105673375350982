angular.module('FmsApp').controller("AddEditEmergencyContactController",
    function ($scope, KhutilsFarmListFactory, EmergencyContactFactory, farm_slug, $mdDialog, $khMessage, $filter, GenericUtilsService) {

        if (farm_slug) {
            $scope.dialogTitle = "Edit emergency contact";
            $scope.farm_slug = farm_slug;
            $scope.emergencyContact = EmergencyContactFactory.get({farm_slug: farm_slug}, function (result) {
                $scope.emergencyContact = result;
            });
        } else {
            $scope.dialogTitle = "Add emergency contact";
            $scope.all_emergency_contacts = EmergencyContactFactory.query();
            $scope.emergencyContact = {};
        }

        $scope.farm_dict = KhutilsFarmListFactory.query({"as_dict": "yes"}, function (response) {
            $scope.farm_dict = response;
            EmergencyContactFactory.query(function (result) {
                $scope.farms_with_emergency_contacts = result.map(function (contact) {
                    return contact.farm_slug;
                });
                $scope.EmergencyContactForm = EmergencyContactForm($scope);
            });
        });


        $scope.sendData = function () {
            if (farm_slug) {
                EmergencyContactFactory.update({farm_slug: farm_slug}, $scope.emergencyContact, function (response) {
                    $mdDialog.hide(response);
                }, function (error) {
                    GenericUtilsService.handleError($filter, $khMessage, error);
                })
            } else {
                EmergencyContactFactory.save($scope.emergencyContact, function (response) {
                    $mdDialog.hide(response);
                }, function (error) {
                    GenericUtilsService.handleError($filter, $khMessage, error);
                })
            }
        };

        var farmForm = farmForm || {};
        var EmergencyContactForm = EmergencyContactForm || {};
        var healthAndSafetyForm = healthAndSafetyForm || {};
        var environmentAgencyForm = environmentAgencyForm || {};
        var utilityForm = utilityForm || {};
        var locationForm = locationForm || {};
        var utilityLocationForm = utilityLocationForm || {};
        var otherLocationForm = otherLocationForm || {};
        var phoneNumberPlaceHolder = "Plus sign followed by code and number";

        EmergencyContactForm = function (scope) {
            return [{
                wrapper: "panel",
                templateOptions: {
                    label: "General"
                },
                className: "layout-xs-column layout-sm-column layout-gt-sm-row felx-100",
                fieldGroup: farmForm(scope)
            }, {
                wrapper: "panel",
                templateOptions: {
                    label: "Health and Safety"
                },
                className: "layout-xs-column layout-sm-column layout-gt-sm-row felx-100",
                fieldGroup: healthAndSafetyForm(scope)
            }, {
                wrapper: "panel",
                templateOptions: {
                    label: "Environment Agency/Govt Dept Phone"
                },
                className: "layout-xs-column layout-sm-column layout-gt-sm-row felx-100",
                fieldGroup: environmentAgencyForm(scope)
            }, {
                wrapper: "panel",
                templateOptions: {
                    label: "Utility Phone"
                },
                className: "layout-xs-column layout-sm-column layout-gt-sm-row felx-100",
                fieldGroup: utilityForm(scope)
            }, {
                wrapper: "panel",
                templateOptions: {
                    label: "Farm Location"
                },
                className: "layout-xs-column layout-sm-column layout-gt-sm-row felx-100",
                fieldGroup: locationForm(scope)
            }, {
                wrapper: "panel",
                templateOptions: {
                    label: "Utility Locations"
                },
                className: "layout-xs-column layout-sm-column layout-gt-sm-row felx-100",
                fieldGroup: utilityLocationForm(scope)
            }, {
                wrapper: "panel",
                templateOptions: {
                    label: "Other Locations"
                },
                className: "layout-xs-column layout-sm-column layout-gt-sm-row felx-100",
                fieldGroup: otherLocationForm(scope)
            }]
        };

        farmForm = function (scope) {
            return [{
                key: 'farm',
                type: 'searchable_select',
                className: 'flex-100',
                templateOptions: {
                    label: "Farm",
                    options: scope.farm_dict,
                    required: true
                },
                "expressionProperties": {
                    "templateOptions.disabled": function () {
                        return scope.farm_slug;
                    }
                },
                validators: {
                    emergencyContactForFarm: {
                        expression: function (viewValue, modelValue, $scope) {
                            if (!scope.farm_slug) {
                                if (modelValue) {
                                    return (scope.farms_with_emergency_contacts.indexOf(modelValue) == -1);
                                } else {
                                    return false;
                                }
                            } else {
                                return true;
                            }
                        },
                        message: '"Emergency contact already exists!"'
                    }
                }
            }];
        };

        var phoneNumberValidation = {
            phoneNumber: {
                expression: function (viewValue, modelValue) {
                    var value = modelValue || viewValue;
                    if (value) {
                        return /[+]([0-9]{11})/.test(value);
                    } else {
                        return true;
                    }
                }
            }
        };
        var requiredPhoneNumberValidation = {
            phoneNumber: {
                expression: function (viewValue, modelValue) {
                    var value = modelValue || viewValue;
                    return /[+]([0-9]{11})/.test(value);
                }
            }
        };

        healthAndSafetyForm = function () {
            return [
                {
                    key: 'phone_doctor',
                    className: "flex-25",
                    type: 'input',
                    templateOptions: {
                        label: "Doctor",
                        required: true
                    },
                    validators: requiredPhoneNumberValidation
                }, {
                    key: 'phone_hospital',
                    className: "flex-25",
                    type: 'input',
                    templateOptions: {
                        label: "Hospital / Emergency",
                        required: true
                    },
                    validators: requiredPhoneNumberValidation
                }, {
                    key: 'phone_vet',
                    className: "flex-25",
                    type: 'input',
                    templateOptions: {
                        label: "Veterinary Surgery"
                    },
                    validators: phoneNumberValidation
                }, {
                    key: 'phone_hse',
                    className: "flex-25",
                    type: 'input',
                    templateOptions: {
                        label: "Health & Safety Department"
                    },
                    validators: phoneNumberValidation
                }
            ];
        };

        environmentAgencyForm = function () {
            return [{
                key: 'phone_ea_general',
                className: "flex-20",
                type: 'input',
                templateOptions: {
                    label: "General"
                },
                validators: phoneNumberValidation
            }, {
                key: 'phone_ea_incident',
                type: "input",
                className: "flex-20",
                templateOptions: {
                    label: "Incident"
                },
                validators: phoneNumberValidation
            }, {
                key: 'phone_ea_flood',
                className: "flex-20",
                type: 'input',
                templateOptions: {
                    label: "Floodline"
                },
                validators: phoneNumberValidation
            }, {
                key: 'phone_ea_pollution',
                className: "flex-20",
                type: 'input',
                templateOptions: {
                    label: "Pollution"
                },
                validators: phoneNumberValidation
            }, {
                key: 'phone_rpa',
                className: "flex-20",
                type: 'input',
                templateOptions: {
                    label: "Rural Payment Agency"
                },
                validators: phoneNumberValidation
            }
            ];
        };

        utilityForm = function () {
            return [{
                key: 'phone_elect',
                className: "flex-33",
                type: 'input',
                templateOptions: {
                    label: "Electricity Supplier"
                },
                validators: phoneNumberValidation
            }, {
                key: 'phone_gas',
                className: "flex-33",
                type: 'input',
                templateOptions: {
                    label: "Gas Supplier"
                },
                validators: phoneNumberValidation
            }, {
                key: 'phone_water',
                className: "flex-33",
                type: 'input',
                templateOptions: {
                    label: "Water Supplier"
                },
                validators: phoneNumberValidation
            }
            ];
        };

        locationForm = function () {
            return [{
                className: "flex-100",
                key: 'farm_dir',
                type: 'textarea',
                id: 'farm_details_textarea',
                templateOptions: {
                    label: "Farm Directions",
                    rows: 3
                }
            }];
        };

        utilityLocationForm = function () {
            return [{
                key: 'phone_loc',
                className: "flex-25",
                type: 'textarea',
                id: 'farm_details_textarea',
                templateOptions: {
                    label: "Nearest Phone",
                    rows: 3
                }
            }, {
                key: 'fire_loc',
                className: "flex-25",
                type: 'textarea',
                id: 'farm_details_textarea',
                templateOptions: {
                    label: "Fire Extinguishers",
                    rows: 3
                }
            }, {
                key: 'elec_iso',
                className: "flex-25",
                type: 'textarea',
                id: 'farm_details_textarea',
                templateOptions: {
                    label: "Electricity Isolation",
                    rows: 3
                }
            }, {
                key: 'gas_iso',
                className: "flex-25",
                type: 'textarea',
                id: 'farm_details_textarea',
                templateOptions: {
                    label: "Gas Isolation",
                    rows: 3
                }
            }
            ];
        };

        otherLocationForm = function () {
            return [{
                key: 'water_loc',
                className: "flex-25",
                type: 'textarea',
                id: 'farm_details_textarea',
                templateOptions: {
                    label: "Water Supply",
                    rows: 3
                }
            }, {
                key: 'washing_loc',
                className: "flex-25",
                type: 'textarea',
                id: 'farm_details_textarea',
                templateOptions: {
                    label: "Nearest Washing",
                    rows: 3
                }
            }, {
                key: 'flammable_loc',
                className: "flex-25",
                type: 'textarea',
                id: 'farm_details_textarea',
                templateOptions: {
                    label: "Flammable",
                    rows: 3
                }
            }, {
                key: 'noxious_loc',
                className: "flex-25",
                type: 'textarea',
                id: 'farm_details_textarea',
                templateOptions: {
                    label: "Poisonous/Noxious",
                    rows: 3
                }
            }
            ];
        };


    });