angular.module('FmsApp').controller("ViewLabController", ["$scope", "$mdDialog", "$khMessage", "$filter",
    "LaboratoryFactory", "slug",
    function ($scope, $mdDialog, $khMessage, $filter, LaboratoryFactory, slug) {

        $scope.dialogTitle = $filter('translate')('soilAnalysis.lab');

        LaboratoryFactory.get({laboratory_slug: slug},
            function (result) {
                $scope.lab = result;
                $scope.lab.type = $scope.lab.type.join(', ')
            });

        $scope.cancel = function () {
            $mdDialog.hide();
        };

    }]);
