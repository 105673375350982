angular.module('FmsApp').factory("FarmProfileFactory", ["$resource", function ($resource) {
    return $resource('api/v1.0/farmdetails/profile/:farm_slug/', {farm_slug: '@farm_slug'},
        {
            save: {
                method: 'POST',
            },
            update: {
                method: 'PUT',
                transformRequest: function (data) {
                    if (data === undefined)
                        return data;
                    var fd = new FormData();
                    for (var key in data) {
                        if (data.hasOwnProperty(key)) {
                            fd.append(key, data[key]);
                        }
                    }
                    return fd;
                },
                headers: {'Content-Type': undefined}
            },
            get: {
                method: 'GET',
                isArray: false
            },
            query: {
                method: 'GET',
                isArray: true
            }
        });
}]);
angular.module('FmsApp').factory("URLFactory", ["$resource", function ($resource) {
    return $resource('api/v1.0/khutils/url/:url_name/', {url_name: '@url_name'});
}]);
