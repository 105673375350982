angular.module('FmsApp').controller("SoilAnalysisLabController",
    function ($scope, $rootScope, $mdDialog, $filter, $khMessage, NgTableParams, LaboratoryFactory,
              FMSGenericHelperService, GenericUtilsService) {

        $rootScope.currentNavItem = "lab";

        FMSGenericHelperService.initController($scope, $rootScope, $filter);
        FMSGenericHelperService.appSideBar("farm", "farm_soil");

        $scope.tableFilters = {
            laboratory_name: FMSGenericHelperService.getFilterDict($filter, 'soilLab', 'laboratory_name'),
            type: FMSGenericHelperService.getFilterDict($filter, 'soilLab', 'type'),
            phone: FMSGenericHelperService.getFilterDict($filter, 'soilLab', 'phone')
        };

        $rootScope.$watch("selected_team", function (newValue, oldValue) {
            if (oldValue != newValue) {
                $khMessage.show('Please wait. Updating data.');
                load_lab_data();
            }
        });

        function load_lab_data() {
            $scope.all_labs = LaboratoryFactory.query(function (result) {
                $scope.all_labs = result;
                $scope.dataAppsTableParameters = new NgTableParams({}, {dataset: $scope.all_labs});
                $khMessage.hide()
            });
        }

        load_lab_data();

        $scope.SoilAnalysisCards = [
            {
                card_title: 'Soil Analysis Labs',
                kh_card_content_template: '/static/assets/khapps/fms_app/partials/soilanalysis/soilanalysis_labs_table.html',
                kh_card_toolbar_filters_template: '',
                card_flex: 100,
                show_progress_bar: true
            }
        ];

        $scope.changeDataView = function (selectedDataView) {
            $scope.currentDataView = selectedDataView;
        };

        $scope.edit = function (slug) {
            $mdDialog.show({
                templateUrl: '/static/assets/khapps/fms_app/partials/soilanalysis/forms/lab_dialog_form.html',
                controller: 'AddEditLabController',
                locals: {
                    slug: slug
                }
            }).then(function (response) {
                $scope.all_labs =GenericUtilsService.updateListAfterEdit($scope.all_labs, "laboratory_slug", slug, response);
                $scope.dataAppsTableParameters = new NgTableParams({}, {dataset: $scope.all_labs});
            });
        };

        $scope.view = function (testSlug) {
            $mdDialog.show({
                templateUrl: '/static/assets/khapps/fms_app/partials/soilanalysis/forms/lab_view_dialog.html',
                controller: 'ViewLabController',
                locals: {
                    slug: testSlug
                }
            })
        };

        $rootScope.$on("create_success", function (broadcast, response) {
            $scope.all_labs.unshift(response);
            $scope.dataAppsTableParameters = new NgTableParams({}, {dataset: $scope.all_labs});
        });


    });
