angular.module('FmsApp').service("FieldDiaryChartService", function (WeatherUtilsService) {

    this.buildChartForFieldDiaryVariable = function (filters, key, chart_data, chart_metadata) {
        data = WeatherUtilsService.sortSeriesData(chart_data)[0].data;
        var unit_name = '';
        if (chart_data.metadata.unit) {
            unit_name += ' (' + chart_data.metadata.unit + ')';
        }
        var chart = {
            chart: {
                events: {
                    load: function () {

                        var centerX = 150,
                            centerY = 100,
                            path = [],
                            angle,
                            radius,
                            badgeColor = Highcharts.Color(Highcharts.getOptions().colors[0]).brighten(-0.2).get(),
                            spike,
                            seriesDataImage,
                            renderer;

                        if (this.chartWidth < 530) {
                            return;
                        }

                        // Draw the spiked disc
                        for (angle = Math.PI / 4; angle < 2 * Math.PI; angle += Math.PI / 2) {
                            radius = 80;
                            path.push(
                                'L',
                                centerX + radius * Math.cos(angle),
                                centerY + radius * Math.sin(angle)
                            );
                            spike = !spike;
                        }
                        path[0] = 'M';
                        path.push('z');
                        this.renderer.path(path)
                            .attr({
                                // fill: '#EFFFFF',
                                zIndex: 6
                            })
                            .add();

                        // Employee image overlay
                        seriesDataImage = this.renderer.path(path)
                            .attr({
                                zIndex: 7,
                                opacity: 0,
                                stroke: badgeColor,
                                'stroke-width': 1
                            })
                            .add();

                        // Prepare mouseover
                        renderer = this.renderer;
                        if (renderer.defs) { // is SVG
                            $.each(data, function () {
                                var point = this,
                                    pattern;
                                if (point.image) {
                                    pattern = renderer.createElement('pattern').attr({
                                        id: 'pattern-' + point.image,
                                        patternUnits: 'userSpaceOnUse',
                                        width: 400,
                                        height: 400
                                    }).add(renderer.defs);
                                    renderer.image(
                                        // 'https://www.highcharts.com/images/employees2014/' + point.image + '.jpg',
                                        point.image,
                                        centerX - 80,
                                        centerY - 80,
                                        160,
                                        213
                                    ).add(pattern);

                                    Highcharts.addEvent(point, 'mouseOver', function () {
                                        seriesDataImage
                                            .attr({
                                                fill: 'url(#pattern-' + point.image + ')'
                                            })
                                            .animate({opacity: 1}, {duration: 500});
                                    });
                                    Highcharts.addEvent(point, 'mouseOut', function () {
                                        seriesDataImage.animate({opacity: 0}, {duration: 500});
                                    });
                                }
                            });
                        }
                    }
                }
            },
            exporting: {
                enabled: false
            },
            title: {
                text: key
            },
            subtitle: {
                text: ''
            },
            yAxis: {
                type: chart_data.metadata.y_axis_type,
                min: chart_data.metadata.y_axis_min,
                max: chart_data.metadata.y_axis_max,
                labels: {
                    format: chart_data.metadata.y_axis_label_format
                },
                lineWidth: 1,
                gridLineWidth: 0,
                title: {
                    text: 'Value' + unit_name
                }
            },
            xAxis: {
                plotBands: chart_data.plot_bands,
                plotLines: chart_data.plot_lines,
                type: 'datetime',
                min: chart_data.metadata.x_axis_min,
                max: chart_data.metadata.x_axis_max,
                dateTimeLabelFormats: {
                    month: '%b %e, %Y'
                },
                tickInterval: chart_data.metadata.tick_interval
            },
            credits: {
                enabled: false
            },
            tooltip: {
                dateTimeLabelFormats: {
                    week: '%A, %b %e, %Y'
                }
            },
            plotOptions: {
                series: {
                    dataGrouping: {
                        dateTimeLabelFormats: {
                            week: ['%A, %b %e, %Y']
                        }
                    },
                    connectNulls: true
                }
            },
            legend: {
                enabled: false
            },
            series: this.getSeriesData(chart_data, chart_metadata)
        };
        if (chart_data.metadata.y_axis_type === 'datetime') {
            chart.tooltip.formatter = function () {
                if (this.series.userOptions.type === 'flags') {
                    return moment(this.x).format('MMM DD, YYYY') + '<br/><b>' + this.point.title + '</b>: ' + this.point.text;
                } else {
                    return moment(this.x).format('MMM DD, YYYY') + '<br/><b>Date: </b>' + moment(this.y).format('MMM DD, YYYY');
                }
            };
        }
        return chart;
    };
    this.getSeriesData = function (chart_data, chart_metadata) {
        for (var i = 0; i < chart_data.data.length; i++) {
            chart_data.data[i].data = chart_data.data[i].data.sort(this.sortByTimeStamp);
        }
        chart_data.data = chart_data.data.concat(chart_metadata.operation_metadata);
        return chart_data.data;
    };

    this.sortByTimeStamp = function (a, b) {
        if (a[0] < b[0]) return -1;
        if (a[0] > b[0]) return 1;
        return 0;
    };

});

