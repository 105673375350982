angular.module('FmsApp')
    .controller('AddEditSeedController',
        function AddEditSeedController($rootScope,
                                       $scope,
                                       $filter,
                                       $khMessage,
                                       $mdDialog,
                                       KhutilsCropListFactory,
                                       PrimarySecondaryTeamOfUserFactory,
                                       V2GenericSeedProductFactory,
                                       V2GenericProductTypesFactory,
                                       CropVarietyFactory,
                                       seed_slug, GenericUtilsService) {

            $scope.allow_public = $rootScope.is_superuser === 'True';
            $scope.dialogTitle = $filter('translate')('farmSetup.add_seed');
            KhutilsCropListFactory.query(function (crop_data) {
                $scope.crops = crop_data;
            });

            function getTeamList(pageNo) {
                PrimarySecondaryTeamOfUserFactory.query({page: pageNo}, function (teams) {
                    $scope.teams = $scope.teams.concat(teams.results);
                    if (teams.next) {
                        getTeamList(pageNo + 1);
                    }
                });
            }

            $scope.teams = [];
            getTeamList(1);

            $scope.getProductTypes = function (pageNo) {
                if (pageNo === 1) $scope.types = [];
                V2GenericProductTypesFactory.query({product_category: 'seed', page: pageNo}, function (types) {
                    $scope.types = $scope.types.concat(types.data);
                    if (types.next) {
                        $scope.getProductTypes(types.current_page + 1);
                    }
                });
            };
            $scope.getProductTypes(1);

            $scope.getVarieties = function (crop_id, pageNo) {
                if (pageNo === 1) $scope.varieties = [];
                CropVarietyFactory.query({include_public: 'yes', crop: crop_id, page: pageNo}, function (result) {
                    $scope.varieties = $scope.varieties.concat(result.data);
                    if (result.next) {
                        $scope.getVarieties(crop_id, result.current_page + 1);
                    }
                });
            };

            if (seed_slug) {
                $scope.dialogTitle = $filter('translate')('farmSetup.edit_seed');
                V2GenericSeedProductFactory.get({product_slug: seed_slug, content: 'edit'}, function (response) {
                    $scope.seed = response.data;
                    $scope.getVarieties(response.data.crop, 1);
                });
            } else {
                var selected_team_slug = ($rootScope.selected_team !== 'All Teams') ? $rootScope.selected_team : null;
                $scope.dialogTitle = $filter('translate')('farmSetup.add_seed');
                $scope.seed = {
                    is_public: false,
                    team_slug: selected_team_slug,
                    manufacturer: 'N/A'
                };
            }

            $scope.sendData = function () {
                if (seed_slug) {
                    V2GenericSeedProductFactory.update({product_slug: seed_slug}, $scope.seed, function (response) {
                        $khMessage.show($filter('translate')('updated_successfully'));
                        $mdDialog.hide(response);
                    }, function (error) {
                        GenericUtilsService.handleErrorV2($filter, $khMessage, error);
                    });
                } else {
                    V2GenericSeedProductFactory.create($scope.seed, function (response) {
                        $khMessage.show($filter('translate')('created_successfully'));
                        $mdDialog.hide(response);
                    }, function (error) {
                        GenericUtilsService.handleErrorV2($filter, $khMessage, error);
                    });
                }
            };

            $scope.cancel = function () {
                $mdDialog.cancel();
            };

            $scope.showAddVarietyDialog = function (crop_id) {
                $mdDialog.show({
                    skipHide: true,
                    multiple: true,
                    templateUrl: '/static/assets/khapps/fms_app/partials/setup/variety/variety_dialog_form.html',
                    controller: 'AddEditVarietyController',
                    locals: {
                        variety_slug: null,
                        crop_id: crop_id
                    }
                }).then(function (response) {
                    $scope.varieties.unshift(response.data);
                    $scope.seed.variety = response.data.variety_slug;
                });
            };

            $scope.isSeedFormInvalid = function () {
                if ($scope.seed) {
                    if (!$scope.seed.name) return true;
                    if (!$scope.seed.crop) return true;
                    if (!$scope.seed.variety) return true;
                    if (!$scope.seed.manufacturer) return true;
                    if (!$scope.seed.team_slug) return true;
                }
            };
        });

