/**
 * Adapted from a suggestion on stackoverflow to make a nicer file upload button
 * I tweaked the original suggestion to pass in the file as isolate scope
 */
angular.module('FmsApp').directive('uploadFileInput', function() {
    return {
        restrict: 'E',
        scope: {
            file: '='
        },
        templateUrl: '/static/assets/khapps/fms_app/controllers/file_upload/upload-file-input.directive.html',
        link: function (scope, element, attrs) {
            let input = $(element[0].querySelector('#fileInput'));
            let button = $(element[0].querySelector('#uploadButton'));
            let textInput = $(element[0].querySelector('#textInput'));

            if (input.length && button.length && textInput.length) {
                button.click(function (e) {
                    input.click();
                });
                textInput.click(function (e) {
                    input.click();
                });
            }

            input.on('change', function (e) {
                let files = e.target.files;
                if (files[0]) {
                    scope.fileName = files[0].name;
                    scope.file = files[0];
                } else {
                    scope.fileName = null;
                }
                scope.$apply();
            });
        }
    }
});
