angular.module('FmsApp').controller('PlantingProgressController',
    function ($rootScope, $scope, PlantingProgressReportFactory, CropListFactory, ReportDataFrequencyFactory, $timeout,
              $khMessage, $filter, PlantingProgressChartService, GenericUtilsService, FarmFiltersService) {

        $rootScope.page_id = 'farm';
        $rootScope.sub_app_page_id = 'farm_reports';
        $rootScope.currentNavItem = 'planting_progress';

        var dataLoaded = false;
        var chartLoadingFirstTime = true;
        $scope.showChart = false;
        $scope.plantingProgressFilters = {};
        $scope.showEmptyState = false;

        $scope.crop_list = CropListFactory.query(function (crop_data) {
            crop_data.unshift({crop_name: 'All Crops', crop_id: null});
            $scope.crop_list = crop_data;
            $scope.data_frequency_list = ReportDataFrequencyFactory.query(function (response) {
                $scope.data_frequency_list = response;
                $scope.PlantingProgressFilter = PlantingProgressFilter($scope);
            });
        });

        $scope.loadReport = function () {
            if (FarmFiltersService.getGrowingSeason() !== 'All Seasons') {
                $khMessage.hide();
                var queryString = {
                    farm: FarmFiltersService.getSelectedFarmSlug(),
                    growing_season: FarmFiltersService.getGrowingSeason(),
                    crop: $scope.plantingProgressFilters.crop,
                    frequency: $scope.plantingProgressFilters.frequency
                };
                $scope.plantingProgressData = PlantingProgressReportFactory.get(queryString, function (response) {
                    if (!GenericUtilsService.isEmpty(response.area_chart_data)) {
                        $scope.showEmptyState = false;
                        $scope.PlantingProgressChart = PlantingProgressChartService.PlantingProgressChart(response);
                        $scope.showChart = true;
                    } else {
                        $scope.showChart = false;
                        $scope.showEmptyState = true;
                    }
                }, function (error) {
                    $khMessage.show($filter('translate')('default_error_message'));
                });
            } else {
                $scope.showChart = false;
                $scope.showEmptyState = true;
                $khMessage.show('Please choose a particular growing season to view planting progress chart.', 20000);
            }
            if (chartLoadingFirstTime) {
                chartLoadingFirstTime = false;
                $scope.loadReport();
            //    loading twice since highchart doesnt load first time!
            }
        };

        $scope.$on('filter:selections_updated', function () {
            dataLoaded = true;
            $scope.loadReport();
        });

        $timeout(loadReportWithoutBroadcast, 2000);

        function loadReportWithoutBroadcast() {
            if (!dataLoaded) {
                $scope.loadReport();
            }
        }

        var PlantingProgressFilter = function ($scope) {
            return [{
                className: "flex-50",
                key: "crop",
                type: "searchable_select",
                defaultValue: $scope.crop_list[0].crop_id,
                templateOptions: {
                    valueProp: 'crop_id',
                    labelProp: 'crop_name',
                    label: "Crop",
                    options: $scope.crop_list
                }
            }, {
                className: "flex-50",
                key: "frequency",
                type: "searchable_select",
                defaultValue: $scope.data_frequency_list[1].value,
                templateOptions: {
                    label: "Frequency",
                    options: $scope.data_frequency_list
                }
            }]
        };
    });
