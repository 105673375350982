angular.module("EventsApp").controller("UpcomingEventsController",
    function ($sce, $filter, $scope, $location, UpcomingEventsFactory, $rootScope, $mdDialog, $khMessage, ArticleUtilsService) {
        $rootScope.pageTitle = "Events/Courses";
        $rootScope.page_id = "events";
        $rootScope.currentNavItem = 'member';

        UpcomingEventsFactory.query(function (result) {
            $scope.upcoming_events = result;
        });

        $scope.clearSearch = function () {
            $scope.search_query = null;
            $scope.controller_search_query = null;
        };

        $scope.searchEvent = function (search_query) {
            $scope.controller_search_query = search_query;
        };

        Array.prototype.contains = function (needle) {
            for (var i in this) {
                if (this[i] == needle) return true;
            }
            return false;
        };

        $scope.EventHelperForDate = function (date) {
            var split = date.split(" ");
            var get_date = ArticleUtilsService.ArticleHelpers().getReadableDate(split[0]);
            var time_str = split[1];
            var get_time = time_str.substring(0, time_str.lastIndexOf('0:') + 1);
            if (get_time) {
                var get_meridian = get_time.split(":")[0];
                if (parseInt(get_meridian) < 12) {
                    get_time = get_time + " AM";
                } else {
                    get_time = get_time + " PM";
                }
            }
            return {
                "date": get_date,
                "time": get_time
            };
        };

        $scope.years = ["2015", "2016"];
        $scope.year = {};
        $scope.regions = ["North", "East", "West", "South"];
        $scope.year.start_date = "2016";

        $scope.checkArtisTrainingEvent = function (event_type_id) {
            var artis_training_event_id = [19, 20, 21, 22, 23, 26];
            return artis_training_event_id.contains(event_type_id);
        };

        $scope.checkNIABShopTrainingEvent = function (event_type_id) {
            var niab_shop_training_event_id = [2, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 27];
            return niab_shop_training_event_id.contains(event_type_id);
        };

        $scope.checkInnovationFarmEvent = function (event_type_id) {
            var innovation_farm_training_event_id = [8];
            return innovation_farm_training_event_id.contains(event_type_id);
        };

        $scope.doNotShowEvent = function (event_type_id) {
            var do_not_show_event_id = [17, 24, 25];
            return do_not_show_event_id.contains(event_type_id);
        };

        $scope.checkIfPermission = function () {
            return ArticleUtilsService.ArticleHelpers().checkGlobalEditPermission();
        };

        $scope.artis_logo_path = require('../../../khapps2/base/khutils/images/articles/artis_logo.png');
        $scope.niab_tag_logo_path = require('../../../khapps2/base/khutils/images/articles/niabtag.png');
        $scope.niab_logo_path = require('../../../../dashboard/client/niab/loader_logo.png');
        $scope.innovationfarm_logo_path = require('../../../khapps2/base/khutils/images/articles/innovationfarm.jpg');

        $rootScope.$on("region_filtered", function (ev, item) {
            $scope.selected_region = item.name;
        });

        $rootScope.$on("selected_year", function (ev, item) {
            $scope.selected_year = item.value;
        });

        $scope.removeItem1 = function () {
            delete $scope.selected_region;
            $rootScope.$broadcast("region_filtered_clear");
        };

        $scope.removeItem2 = function () {
            delete $scope.selected_year;
            $rootScope.$broadcast("year_filtered_clear");
        };

        $scope.clearAllFilter = function () {
            $scope.clearSearch();
            $scope.removeItem1();
            $scope.removeItem2();
        };

        $scope.deleteEvent = function (event) {
            var confirmDelete = $mdDialog.confirm()
                .title("Delete event")
                .textContent("Are your sure you want to delete the event?")
                .ariaLabel("Delete Event")
                .ok("Delete")
                .cancel("Cancel");

            $mdDialog.show(confirmDelete).then(function () {
                UpcomingEventsFactory.delete({event_id: event.id}, function () {
                    for (var i = 0; i < $scope.upcoming_events.length; i++) {
                        if (event.id == $scope.upcoming_events[i].id) {
                            $scope.upcoming_events.splice(i, 1);
                        }
                    }
                });
                $khMessage.show("Event Deleted", 3000);
            }, function () {
            });
        };
        $scope.parseTitle = function (title) {
            return title.replace(/ /g, "_");
        }
    });