angular.module('FmsApp').factory("PlantingSeasonListFactory", ["$resource", function ($resource) {
    return $resource('/api/v1.0/khutils/plantingseasons/');
}]);
angular.module('FmsApp').factory("CropListFactory", ["$resource", function ($resource) {
    return $resource('/api/v1.0/fieldplotcrop/croplist/');
}]);
angular.module('FmsApp').factory("CurrentSeasonFactory", ["$resource", function ($resource) {
    return $resource('/api/v1.0/khutils/currentplantingseason/', {},
        {
            query: {
                method: 'GET',
                isArray: false
            }
        });
}]);
angular.module('FmsApp').factory("ReportDataFrequencyFactory", ["$resource", function ($resource) {
    return $resource('/api/v1.0/farm-reports/data-frequency/')
}]);