angular.module('FmsApp').controller("ViewFarmCertificateController", ["$scope", "FarmCertificateFactory", "record_slug",
    "DateTimeHelperFactory",
    function ($scope, FarmCertificateFactory, record_slug, DateTimeHelperFactory) {
        $scope.certificate = FarmCertificateFactory.get({record_slug: record_slug, view_only: "yes"},
            function (result) {
                $scope.certificate = result;
                $scope.certificate.certification_date = DateTimeHelperFactory.convertDateFormatUsingMoment(
                    $scope.certificate.certification_date);
                if ($scope.certificate.expiry_date) {
                    $scope.certificate.expiry_date = DateTimeHelperFactory.convertDateFormatUsingMoment(
                        $scope.certificate.expiry_date);
                }
                if ($scope.certificate.renewal_date) {
                    $scope.certificate.renewal_date = DateTimeHelperFactory.convertDateFormatUsingMoment(
                        $scope.certificate.renewal_date);
                }
            });
    }]);