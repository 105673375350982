angular.module('FmsApp').controller("ProgressController", ["$rootScope", "$scope", "$filter", function ($rootScope, $scope, $filter) {

    $scope.plantingProgressCard = [{
        card_title: $filter('translate')('farmReports.planting_progress'),
        kh_card_content_template: '/static/assets/khapps/fms_app/partials/farmreports/planting_progress.html',
        card_flex: 100,
        show_progress_bar: true
    }];


    $scope.inseasonReportCards = [{
        card_title: $filter('translate')('farmReports.inseason_progress'),
        kh_card_content_template: '/static/assets/khapps/fms_app/partials/farmreports/field_diary.html',
        card_flex: 100,
        show_progress_bar: true
    }];

}
]);
