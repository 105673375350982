angular.module('FmsApp').controller("AddEditFarmCertificateController",
    function ($rootScope, $scope, FarmCertificateFactory, AssuranceFactory, KhutilsFarmListFactory, $mdDialog, record_slug,
              $khMessage, $filter, GenericUtilsService,CachingUtilsService) {

        if (record_slug) {
            $scope.dialogTitle = "Edit farm certificate";
            $scope.certificate = FarmCertificateFactory.get({record_slug: record_slug}, function (result) {
                $scope.certificate = result;
                $scope.certificate.certification_date = moment($scope.certificate.certification_date, "YYYY-MM-DD").toDate();
                $scope.certificate.renewal_date = moment($scope.certificate.renewal_date, "YYYY-MM-DD").toDate();
                $scope.certificate.expiry_date = moment($scope.certificate.expiry_date, "YYYY-MM-DD").toDate();
            });
        } else {
            $scope.dialogTitle = "Add farm certificate";
            $scope.certificate = {};

        }

        var farmCertificateForm = function (scope, dateFormat) {
            return [{
                className: "flex-100",
                key: 'farm',
                type: 'searchable_select',
                templateOptions: {
                    label: "Farm",
                    options: scope.farm_dict,
                    required: true
                },
                "expressionProperties": {
                    "templateOptions.disabled": 'model.record_slug'
                }
            }, {
                className: "flex-50",
                key: 'certification',
                type: 'searchable_select',
                templateOptions: {
                    label: "Certification",
                    options: scope.assurance_dict,
                    required: true
                },
                "expressionProperties": {
                    "templateOptions.disabled": 'model.record_slug'
                }
            }, {
                className: "flex-50",
                key: 'certified_by',
                type: 'input',
                templateOptions: {
                    label: "Certified by",
                    required: true
                }
            }, {
                className: "flex-50",
                key: 'registration_number',
                type: 'input',
                templateOptions: {
                    label: "Registration Number",
                    required: true
                }
            }, {
                className: "flex-50",
                "type": "datepicker",
                "key": "certification_date",
                templateOptions: {
                    label: "Certification Date",
                    required: true,
                    placeholder: dateFormat
                }
            }, {
                className: "flex-50",
                "type": "datepicker",
                "key": "renewal_date",
                templateOptions: {
                    label: "Renewal Date",
                    placeholder: dateFormat
                }
            }, {
                className: "flex-50",
                "type": "datepicker",
                "key": "expiry_date",
                templateOptions: {
                    label: "Expiry Date",
                    placeholder: dateFormat
                }
            }, {
                className: "flex-100",
                "type": "textarea",
                "key": "notes",
                templateOptions: {
                    label: "Notes",
                    rows: 3
                }
            }];
        };

        $scope.farm_dict = KhutilsFarmListFactory.query({"as_dict": "yes"}, function (response) {
            $scope.farm_dict = response;
        });
        $scope.assurance_dict = CachingUtilsService.addFactoryToCache(AssuranceFactory, "assurance_dict", null, {"as_dict": "yes"});

        $scope.FarmCertificateForm = farmCertificateForm($scope, "dd/mm/yyyy");

        $scope.sendData = function () {
            var certificateData = angular.copy($scope.certificate);
            certificateData.certification_date = moment($scope.certificate.certification_date).format("YYYY-MM-DD");
            certificateData.renewal_date = moment($scope.certificate.renewal_date).format("YYYY-MM-DD");
            certificateData.expiry_date = moment($scope.certificate.expiry_date).format("YYYY-MM-DD");

            if (record_slug) {
                FarmCertificateFactory.update({record_slug: record_slug}, certificateData,
                    function (response) {
                        $khMessage.show($filter('translate')('updated_successfully'));
                        $mdDialog.hide(response);
                    }, function (error) {
                        GenericUtilsService.handleError($filter, $khMessage, error);
                    })
            } else {
                FarmCertificateFactory.save(certificateData, function (response) {
                    $khMessage.show($filter('translate')('created_successfully'));
                    $mdDialog.hide(response);
                }, function (error) {
                    GenericUtilsService.handleError($filter, $khMessage, error);
                });
            }
        };

    });