angular.module('FmsApp').factory("FarmCertificateFactory", ["$resource", function ($resource) {
    return $resource('api/v1.0/farmdetails/certificate/:record_slug/', {record_slug: '@record_slug'},
        {
            save: {
                method: 'POST'
            },
            update: {
                method: 'PUT'
            },
            get: {
                method: 'GET'
            }
        });
}]);
angular.module('FmsApp').factory("AssuranceFactory", ["$resource", function ($resource) {
    return $resource('api/v1.0/farmdetails/assurance/');
}]);
