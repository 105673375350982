(function(angular){
    "use strict";
    angular.module('FmsApp')
        .factory('V2GenericProductTypesFactory', V2GenericProductTypesFactory);

    function V2GenericProductTypesFactory($resource){
        return $resource('api/v2.0/generic-product/types/', {}, {
            query: {
                isArray: false
            }
        });
    }

})(angular);