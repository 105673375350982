angular.module('FmsApp').controller("FarmReportController", ["$rootScope", "$location", "$scope", "$route",
    "CropRotationReportFactory", "NgTableParams", "$timeout", "$filter", "$khMessage",
    function ($rootScope, $location, $scope, $route, CropRotationReportFactory, NgTableParams, $timeout, $filter,
              $khMessage) {

        $rootScope.page_id = "farm";
        $rootScope.sub_app_page_id = "farm_reports";
        $rootScope.currentNavItem = "farm";

        $scope.currentView = 'chartView';
        $scope.showEmptyState = false;

        $scope.getType = function (variable) {
            return typeof (variable);
        };

        $scope.convertListToString = function (list) {
            var data = [];
            //todo: get default area unit by api
            for (var i = 0; i < list.length; i++) {
                data.push(list[i].crop + " (" + list[i].area + " ha)");
            }
            return data.join(', ');
        };

        function loadCropRotationReport() {
            var selected_farm = $rootScope.selected_farm;
            if (selected_farm && selected_farm != "All Farms") {
                var queryString = {
                    farm: selected_farm.value
                }
            }
            $scope.cropRotationData = CropRotationReportFactory.get(queryString, function (response) {
                if (response.chart_data) {
                    $scope.showEmptyState = false;
                    $scope.showReport = true;
                    $scope.CropRotationChart = CropRotationChart(response.chart_data);
                    $scope.seasons = response.chart_data.categories;
                    $scope.cropwiseColumns = [];
                    for (var key in response.crop_wise[0]) {
                        if (response.crop_wise[0].hasOwnProperty(key)) {
                            var dictionary = {
                                field: key,
                                show: true,
                                sortable: key
                            };
                            if (key == "crop_name") {
                                dictionary["thtitle"] = $filter('translate')('farmReports.crop');
                                var filterDict = {};
                                filterDict[key] = {
                                    id: 'text',
                                    placeholder: $filter('translate')('farmReports.filter_by_crop')
                                };
                                dictionary["tooltiptext"] = $filter('translate')('farmReports.crop');
                                dictionary["filter"] = filterDict;
                            }
                            else {
                                dictionary["thtitle"] = key + " (ha)";
                                dictionary["tooltiptext"] = key;
                            }

                            $scope.cropwiseColumns.push(dictionary);
                        }
                    }
                    $scope.fieldwiseColumns = [];
                    for (var key in response.field_wise[0]) {
                        if (response.field_wise[0].hasOwnProperty(key)) {
                            var dictionary = {
                                field: key,
                                show: true,
                                sortable: key
                            };
                            if (key == "field_name") {
                                dictionary["thtitle"] = $filter('translate')('farmReports.field');
                                var fieldFilter = {};
                                fieldFilter[key] = {
                                    id: 'text',
                                    placeholder: $filter('translate')('farmReports.filter_by_field')
                                };
                                dictionary["tooltiptext"] = $filter('translate')('farmReports.field');
                                dictionary["filter"] = fieldFilter;
                            }
                            else if (key == "farm_name") {
                                dictionary["thtitle"] = $filter('translate')('farmReports.farm');
                                var farmFilter = {};
                                farmFilter[key] = {
                                    id: 'text',
                                    placeholder: $filter('translate')('farmReports.filter_by_farm')
                                };
                                dictionary["tooltiptext"] = $filter('translate')('farmReports.farm');
                                dictionary["filter"] = farmFilter;
                            }
                            else {
                                dictionary["thtitle"] = key;
                            }
                        }
                        $scope.fieldwiseColumns.push(dictionary);
                    }
                    $scope.CropwiseRotationTable = new NgTableParams({}, {dataset: response.crop_wise});
                    $scope.FieldwiseRotationTable = new NgTableParams({}, {dataset: response.field_wise});
                } else {
                    $scope.showReport = false;
                    $scope.showEmptyState = true;
                }
            }, function (error) {
                $scope.showReport = false;
                $khMessage.show($filter('translate')('default_error_message'));
            });
        }

        $scope.$on("filter:selections_updated",
            function () {
                loadCropRotationReport();
            }
        );

        $scope.$on('$routeChangeSuccess', function (scope, next, previous) {
            if (previous) loadCropRotationReport();
        });

        $scope.farmReportCards = [{
            card_title: $filter('translate')('farmReports.crop_rotation'),
            kh_card_content_template: '/static/assets/khapps/fms_app/partials/farmreports/crop_distribution.html',
            card_flex: 100,
            show_progress_bar: true
        }];


        CropRotationChart = function (chart_data) {
            return {
                colors: chart_data.colors,
                title: {
                    text: ''
                },
                lang: {
                    noData: 'No data'
                },
                xAxis: [{
                    categories: chart_data.categories
                }],
                yAxis: {
                    min: 0,
                    title: {
                        text: 'Area under cultivation (ha)'
                    },
                    stackLabels: {
                        enabled: true,
                        style: {
                            fontWeight: 'bold',
                            color: 'gray'
                        }
                    }
                },
                credits: {
                    enabled: false
                },
                tooltip: {
                    headerFormat: '<b>{point.x}</b><br/>',
                    pointFormat: '{series.name}: {point.y}<br/>Total: {point.stackTotal}'
                },
                legend: {
                    enabled: true,
                    align: 'center',
                    verticalAlign: 'bottom',
                    backgroundColor: 'white',
                    borderColor: '#CCC',
                    borderWidth: 1,
                    shadow: false
                },
                plotOptions: {
                    column: {
                        stacking: 'normal',
                        dataLabels: {
                            enabled: true,
                            color: 'white'
                        }
                    }
                },
                series: chart_data.series
            };
        };


    }
]);
