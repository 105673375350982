angular.module('FmsApp').factory("FarmEquipmentFactory", ["$resource", function ($resource) {
    return $resource('api/v1.0/farmequipment/data/:equipment_slug/', {equipment_slug: '@equipment_slug'},
        {
            save: {
                method: 'POST'
            },
            update: {
                method: 'PUT'
            },
            get: {
                method: 'GET'
            }
        });
}]);
angular.module('FmsApp').factory("TypesOfFarmEquipmentFactory", ["$resource", function ($resource) {
    return $resource('api/v1.0/farmequipment/types/');
}]);