angular.module('FmsApp')
    .controller('SeedController', function SeedController($scope,
                                                          $rootScope,
                                                          $mdDialog,
                                                          NgTableParams,
                                                          $filter,
                                                          V2GenericSeedProductFactory,
                                                          FMSSetUpHelperService,
                                                          GenericUtilsService) {
        $rootScope.page_id = 'farm';
        $rootScope.sub_app_page_id = 'farm_setup';
        $rootScope.currentNavItem = 'seed';
        $scope.showEmptyState = false;
        $scope.is_superuser = $rootScope.is_superuser === 'True';

        $scope.$on('team_changed',
            function () {
                $scope.all_seeds = [];
                getSeedList(1);
            }
        );

        $scope.$on('seedAdded',
            function (broadcast, response) {
                $scope.all_seeds.unshift(response.data);
                $scope.seedTable = new NgTableParams({}, {dataset: $scope.all_seeds});
                $scope.showEmptyState = false;
            }
        );

        function getSeedList(pageNo) {
            V2GenericSeedProductFactory.query({include_public: 'yes', page: pageNo}, function (response) {
                $scope.all_seeds = $scope.all_seeds.concat(response.data);
                if (response.next) {
                    getSeedList(response.current_page + 1);
                }
                $scope.seedTable = new NgTableParams({}, {dataset: $scope.all_seeds});
                $scope.showEmptyState = (response.total_count === 0);
            });
        }

        $scope.all_seeds = [];
        getSeedList(1);

        $scope.seedListCard = [{
            card_title: $filter('translate')('farmSetup.seed'),
            kh_card_content_template: '/static/assets/khapps/fms_app/partials/setup/seed/seed_list.html',
            card_flex: 100,
            show_progress_bar: true
        }];

        $scope.seedTableFilters = {
            variety_name: FMSSetUpHelperService.getFilterDict($filter, 'variety_name', 'text', 'farmSetup.filter_by_variety'),
            crop_name: FMSSetUpHelperService.getFilterDict($filter, 'crop_name', 'text', 'farmSetup.filter_by_crop'),
            name: FMSSetUpHelperService.getFilterDict($filter, 'name', 'text', 'farmSetup.filter_by_name'),
            team: FMSSetUpHelperService.getFilterDict($filter, 'team_name', 'text', 'farmSetup.filter_by_team'),
            manufacturer: FMSSetUpHelperService.getFilterDict($filter, 'manufacturer', 'text', 'farmSetup.filter_by_manufacturer'),
            is_public: FMSSetUpHelperService.getFilterDict($filter, 'is_public', 'select', 'farmSetup.filter_by_ownership')
        };

        $scope.ownershipList = [
            {id: null, title: 'All'},
            {id: true, title: 'Public'},
            {id: false, title: 'Proprietary'}
        ];

        $scope.openSeedDetailModal = function (product_slug) {
            $mdDialog.show({
                templateUrl: '/static/assets/khapps/fms_app/partials/setup/seed/seed_detail_dialog.html',
                controller: 'SeedDetailController',
                locals: {
                    product_slug: product_slug
                }
            }).then(function () {
            }, function () {
            });
        };

        $scope.editSeed = function (seed_slug) {
            $mdDialog.show({
                templateUrl: '/static/assets/khapps/fms_app/partials/setup/seed/seed_dialog_form.html',
                controller: 'AddEditSeedController',
                locals: {
                    seed_slug: seed_slug
                }
            }).then(function (response) {
                $scope.all_seeds = GenericUtilsService.updateListAfterEdit($scope.all_seeds, 'product_slug', seed_slug, response.data);
                $scope.seedTable = new NgTableParams({}, {dataset: $scope.all_seeds});
            });
        };

    });

