angular.module('FmsApp').controller("EmergencyContactListController",
    function ($rootScope, $scope, EmergencyContactFactory, NgTableParams, $mdDialog, $khMessage, $filter, GenericUtilsService) {

        $rootScope.page_id = "farm";
        $rootScope.sub_app_page_id = "farm_profile";
        $rootScope.currentNavItem = "emergency_contact";

        $scope.showEmptyState = false;
        function loadEmergencyContacts() {
            EmergencyContactFactory.query(function (result) {
                $scope.showEmptyState = (result.length == 0);
                $scope.all_emergency_contacts = result;
                $scope.emergencyContactsTable = new NgTableParams({}, {dataset: $scope.all_emergency_contacts});
            }, function (error) {
                $khMessage.show($filter('translate')('default_error_message'));
            });
        }

        loadEmergencyContacts();

        $scope.viewEmergencyContact = function (slug) {
            $mdDialog.show({
                animation: true,
                templateUrl: '/static/assets/khapps/fms_app/partials/farmdetails/emergency_contact/view_emergency_contact_dialog.html',
                controller: 'ViewEmergencyContactController',
                scope: $scope,
                preserveScope: true,
                locals: {
                    farm_slug: slug
                }
            })
        };

        $scope.editEmergencyContact = function (slug, name) {
            $mdDialog.show({
                templateUrl: '/static/assets/khapps/fms_app/partials/farmdetails/emergency_contact/add_edit_emergency_contact.html',
                controller: 'AddEditEmergencyContactController',
                scope: $scope,
                preserveScope: true,
                locals: {
                    farm_slug: slug,
                    farm_name: name
                }
            }).then(function (result) {
                $scope.all_emergency_contacts = GenericUtilsService.updateListAfterEdit($scope.all_emergency_contacts, "farm_slug", slug, result);
                $scope.emergencyContactsTable = new NgTableParams({}, {dataset: $scope.all_emergency_contacts});
                $khMessage.show($filter('translate')('updated_successfully'), 5000);
            }, function () {
            });
        };

        $scope.emergencyContactListCard = [{
            card_title: $filter('translate')('farmDetails.emergency_contact'),
            kh_card_content_template: '/static/assets/khapps/fms_app/partials/farmdetails/emergency_contact/emergency_contact_list.html',
            card_flex: 100,
            show_progress_bar: true
        }];

        $scope.$on("emergencyContactAdded", function (boradcast, response) {
            if (response.farm_slug) {
                $scope.all_emergency_contacts.unshift(response);
                $scope.emergencyContactsTable = new NgTableParams({}, {dataset: $scope.all_emergency_contacts});
                $scope.showEmptyState = false;
            }
        });

        $scope.$on("team_changed", function (boradcast, response) {
            loadEmergencyContacts();
        });

        $scope.filterByFarm = {
            farm: {
                id: 'text',
                placeholder: $filter('translate')('farmDetails.filter_by_farm')
            }
        };

    });
