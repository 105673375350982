/**
 * Simple chart to show the slow query counts over time
 */
angular.module('FmsApp').component('slowQueriesChart', {
    templateUrl: '/static/assets/khapps/fms_app/admin/slow-queries-chart.component.html',
    controller: function ($http) {
        var self = this;
        self.loading = true;
        $http.get('/api/admin/slow-requests/counts/time-series/').then(function (resp) {
            var series = _.map(resp.data, function(counts_over_threshold) {
                return {
                    type: 'line',
                    name: "Over "+counts_over_threshold.threshold+"s",
                    data: _.map(counts_over_threshold.data, function(datum) {
                        return [datum.ts, datum.count]
                    })
                }
            });
            self.loading = false;

            self.config = {
                chart: {
                    zoomType: 'x'
                },
                title: {
                    text: 'Slow query counts over time'
                },
                subtitle: {
                    text: document.ontouchstart === undefined ?
                        'Click and drag in the plot area to zoom in' : 'Pinch the chart to zoom in'
                },
                xAxis: {
                    type: 'datetime'
                },
                yAxis: {
                    title: {
                        text: 'Total time taken for slow queries'
                    }
                },
                legend: {
                    enabled: true
                },
                series: series
            }
        });
    }
});
