(function (angular) {
    "use strict";
    angular.module('FmsApp')
        .controller('SeedDetailController', [
            "$scope", "product_slug", "$filter", "V2GenericSeedProductFactory",
            SeedDetailController
        ]);

    function SeedDetailController($scope, product_slug, $filter, V2GenericSeedProductFactory) {
        $scope.dialogTitle = $filter('translate')('farmSetup.seed');
        V2GenericSeedProductFactory.get({product_slug: product_slug}, function (response) {
            $scope.seed = response.data;
        })
    }
})(angular);
