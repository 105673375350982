angular.module('FmsApp').controller("FarmCertificateListController",
    function ($rootScope, $scope, FarmCertificateFactory, NgTableParams, $mdDialog, $khMessage, $filter,
              DateTimeHelperFactory, GenericUtilsService) {

        $rootScope.page_id = "farm";
        $rootScope.sub_app_page_id = "farm_profile";
        $rootScope.currentNavItem = "certificate";

        $scope.showEmptyState = false;
        function loadFarmCertificates() {
            FarmCertificateFactory.query(function (result) {
                $scope.showEmptyState = (result.length == 0);
                $scope.all_farm_certificates = result;
                angular.forEach($scope.all_farm_certificates, function (certificate) {
                    certificate.readable_certification_date = DateTimeHelperFactory.convertDateFormatUsingMoment(certificate.certification_date);
                });
                $scope.certificatesTable = new NgTableParams({}, {dataset: $scope.all_farm_certificates});
            }, function (error) {
                $khMessage.show($filter('translate')('default_error_message'));
            });
        }

        loadFarmCertificates();

        $scope.viewFarmCertificate = function (slug) {
            $mdDialog.show({
                animation: true,
                templateUrl: '/static/assets/khapps/fms_app/partials/farmdetails/certificate/view_farm_certificate_dialog.html',
                controller: 'ViewFarmCertificateController',
                scope: $scope,
                preserveScope: true,
                locals: {
                    record_slug: slug
                }
            })
        };

        $scope.editFarmCertificate = function (slug) {
            $mdDialog.show({
                templateUrl: '/static/assets/khapps/fms_app/partials/farmdetails/certificate/add_edit_farm_certificate_dialog.html',
                controller: 'AddEditFarmCertificateController',
                scope: $scope,
                preserveScope: true,
                locals: {
                    record_slug: slug
                }
            }).then(function (result) {
                result.readable_certification_date = DateTimeHelperFactory.convertDateFormatUsingMoment(result.certification_date);
                $scope.all_farm_certificates = GenericUtilsService.updateListAfterEdit($scope.all_farm_certificates, "farm_slug", slug, result);
                $scope.certificatesTable = new NgTableParams({}, {dataset: $scope.all_farm_certificates});
            }, function () {
            });
        };

        $scope.farmCertificatesListCard = [{
            card_title: $filter('translate')('farmDetails.farm_certificates'),
            kh_card_content_template: '/static/assets/khapps/fms_app/partials/farmdetails/certificate/farm_certificate_list.html',
            card_flex: 100,
            show_progress_bar: true
        }];

        $scope.$on("team_changed", function (boradcast, response) {
            loadFarmCertificates();
        });

        $scope.$on("farmCertificateAdded", function (boradcast, response) {
            response.readable_certification_date = DateTimeHelperFactory.convertDateFormatUsingMoment(response.certification_date);
            $scope.all_farm_certificates.unshift(response);
            $scope.certificatesTable = new NgTableParams({}, {dataset: $scope.all_farm_certificates});
            $scope.showEmptyState = false;
        });

        $scope.filterByFarm = {
            farm: {
                id: 'text',
                placeholder: $filter('translate')('farmDetails.filter_by_farm')
            }
        };

        $scope.filterByCertificate = {
            certification: {
                id: 'text',
                placeholder: $filter('translate')('farmDetails.filter_by_certification')
            }
        };

        $scope.filterByDate = {
            readable_certification_date: {
                id: 'text',
                placeholder: $filter('translate')('farmDetails.filter_by_date')
            }
        };

    });
