angular.module('FmsApp').controller("WaterSourceListController", ["$rootScope", "$scope", "WaterSourceFactory", "NgTableParams",
    "$mdDialog", "$filter",
    function ($rootScope, $scope, WaterSourceFactory, NgTableParams, $mdDialog, $filter) {

        $rootScope.page_id = "farm";
        $rootScope.sub_app_page_id = "farm_profile";
        $rootScope.currentNavItem = "water_source";

        $scope.showEmptyState = false;
        function loadWaterSources() {
            $scope.all_water_sources = WaterSourceFactory.query(function (result) {
                $scope.showEmptyState = (result.length == 0);
                $scope.all_water_sources = result;
                $scope.waterSourcesTable = new NgTableParams({}, {dataset:result});
            });
        }

        loadWaterSources();

        $scope.waterSourceListCard = [{
            card_title: $filter('translate')('farmDetails.water_sources'),
            kh_card_content_template: '/static/assets/khapps/fms_app/partials/farmdetails/water_source/water_source_list.html',
            card_flex: 100,
            show_progress_bar: true
        }];

        $scope.editWaterSource = function (source_slug) {
            $mdDialog.show({
                controller: 'AddEditWaterSourceController',
                templateUrl: '/static/assets/khapps/fms_app/partials/farmdetails/water_source/water_source_dialog_form.html',
                locals: {
                    source_slug: source_slug
                }
            }).then(function (response) {
                for (var i = 0; i < $scope.all_water_sources.length; i++) {
                    if ($scope.all_water_sources[i].source_slug == source_slug) {
                        $scope.all_water_sources[i] = response;
                    }
                    $scope.waterSourcesTable = new NgTableParams({}, {dataset: $scope.all_water_sources});
                }
            })
        };

        $scope.$on("waterSourceAdded", function (boradcast, response) {
            $scope.all_water_sources.unshift(response);
            $scope.waterSourcesTable = new NgTableParams({}, {dataset: $scope.all_water_sources});
            $scope.showEmptyState = false;
        });

        $scope.$on("team_changed", function () {
            loadWaterSources();
        });

        $scope.filterByWaterSourceName = {
            source_name: {
                id: 'text',
                placeholder: $filter('translate')('farmDetails.filter_by_name')
            }
        };

        $scope.filterByType = {
            type: {
                id: 'text',
                placeholder: $filter('translate')('farmDetails.filter_by_type')
            }
        }

    }]);
