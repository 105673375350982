angular.module('FmsApp').controller("SharingController", ["$scope", "$rootScope", "$mdDialog", "$filter", "$khMessage", "$khPulse", "NgTableParams",
    "SharingInventoryFactory", "PlatformSettingsFactory","FMSGenericHelperService",
    function ($scope, $rootScope, $mdDialog, $filter, $khMessage, $khPulse, NgTableParams, SharingInventoryFactory, PlatformSettingsFactory, FMSGenericHelperService) {
        $rootScope.page_id = "farm";
        $rootScope.sub_app_page_id = "farm_settings";
        $rootScope.currentNavItem = "sharing";
        $scope.showEmptyState = false;

        FMSGenericHelperService.initController($scope, $rootScope, $filter);
        FMSGenericHelperService.appSideBar("farm", "farm_settings");


        $scope.tableFilters = {
            team_name: FMSGenericHelperService.getFilterDict($filter, 'sharingInventory', 'team_name'),
            object_type: FMSGenericHelperService.getFilterDict($filter, 'sharingInventory', 'object_type'),
            object_name: FMSGenericHelperService.getFilterDict($filter, 'sharingInventory', 'object_name'),
            shared_with: FMSGenericHelperService.getFilterDict($filter, 'sharingInventory', 'shared_with'),
            shared_permission: FMSGenericHelperService.getFilterDict($filter, 'sharingInventory', 'shared_permission'),
        };

        PlatformSettingsFactory.get(function (data) {
            $scope.default_plot_name_prefix = data.PLOT_NAME_PREFIX;
            $scope.default_plot_name_suffix = data.PLOT_NAME_SUFFIX;
            $scope.default_zoomlevel = data.DEFAULT_CENTER_ZOOM;
            $scope.default_latval = data.DEFAULT_CENTER_LAT;
            $scope.default_lngval = data.DEFAULT_CENTER_LONG;
            $scope.planting_season_current = data.PLANTING_SEASON_CURRENT;
            if (typeof($rootScope.selected_growing_season) === 'undefined') {
                $rootScope.selected_growing_season = {}
                $rootScope.selected_growing_season.name = $scope.planting_season_current;
                $rootScope.selected_growing_season.value = $scope.planting_season_current;
                $scope.$broadcast('filter:selected_growing_season');
            }
        });

        $scope.$on("filter:selections_updated", function (obj, filterValue) {
            if (filterValue.hasOwnProperty('growing_season')) {
                if (typeof($rootScope.selected_growing_season) !== 'undefined') {
                    $khMessage.show('Please wait. Updating data.');
                    load_sharing_table_data();
                }
            }
        });

        $scope.$on('$routeChangeSuccess', function (scope, next, previous) {
            if (previous) {
                $khMessage.show('Please wait. Updating data.');
                load_sharing_table_data();
            }
        });

        function load_sharing_table_data() {
            $khPulse.show();
            SharingInventoryFactory.query({growing_season: $rootScope.selected_growing_season.value}, function (result) {
                $scope.shared_data = result.data;
                $scope.dataAppsTableParameters = new NgTableParams({}, {dataset: $scope.shared_data});
                $khMessage.hide();
                $khPulse.hide(0);
            });
        }

        $scope.SharingCards = [
            {
                card_title: 'Manage Sharing',
                kh_card_content_template: '/static/assets/khapps/fms_app/partials/manage/sharing/sharing_table.html',
                kh_card_toolbar_filters_template: '',
                card_flex: 100
            }
        ];

        $rootScope.shareObject = function (object_type, object_slug) {
            $mdDialog.show({
                controller: "ShareWithAddEditController",
                templateUrl: "/static/assets/khapps/profiles/share_with/partials/share_with_form.html",
                parent: angular.element(document.body),
                animation: true,
                scope: $scope,
                preserveScope: true,
                clickOutsideToClose: true,
                locals: {object_slug: object_slug, object_type: object_type}
            });
        };

    }]);
