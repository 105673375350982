angular.module('FmsApp').factory("LaboratoryFactory", ["$resource", function ($resource) {
    return $resource('api/v1.0/laboratory/data/:laboratory_slug/', {laboratory_slug: '@laboratory_slug'},
        {
            save: {
                method: 'POST'
            },
            update: {
                method: 'PUT'
            },
            get: {
                method: 'GET'
            }
        });
}]);
angular.module('FmsApp').factory("LaboratoryTypesFactory", ["$resource", function ($resource) {
    return $resource('api/v1.0/laboratory/types/');
}]);
