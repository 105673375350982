angular.module('FmsApp').controller("AddEditFarmProfileController",
    function ($scope, FarmProfileFactory, KhutilsFarmListFactory, TeamUsersFactory, $mdDialog, farm_slug, $khMessage,
              $filter, GenericUtilsService) {

        $scope.farm_slug = farm_slug;

        var baseForm = function (scope) {
            return [{
                className: "flex-33",
                key: 'farm',
                type: 'searchable_select',
                templateOptions: {
                    label: "Farm",
                    options: scope.farm_dict,
                    required: true
                },
                "expressionProperties": {
                    "templateOptions.disabled": function () {
                        return scope.farm_slug;
                    }
                },
                validators: {
                    farm: {
                        expression: function (viewValue, modelValue, $scope) {
                            if (!scope.farm_slug) {
                                if (modelValue) {
                                    return (scope.farms_with_profile.indexOf(modelValue) == -1);
                                } else {
                                    return false;
                                }
                            } else {
                                return true;
                            }
                        },
                        message: '"Profile already exists!"'
                    }
                }
            }, {
                className: "flex-33",
                key: 'farm_manager',
                type: 'searchable_select',
                templateOptions: {
                    label: "Farm Manager",
                    options: scope.teamusers
                }
            }, {
                className: "flex-33",
                key: 'operational_since',
                type: 'input',
                templateOptions: {
                    label: "Operational Since",
                    required: true,
                    type: "text"
                }
            }, {
                className: "flex-33",
                key: 'desc',
                type: 'textarea',
                templateOptions: {
                    label: "Description",
                    rows: 3,
                    required: true
                }
            }, {
                className: "flex-33",
                key: 'produce',
                type: 'textarea',
                templateOptions: {
                    label: "About Produce",
                    rows: 3,
                    required: true
                }
            }, {
                className: "flex-33",
                key: 'achievements',
                type: 'textarea',
                templateOptions: {
                    label: "Achievements",
                    rows: 3
                }
            }, {
                className: "flex-25",
                key: 'url',
                type: 'input',
                templateOptions: {
                    label: "Website"
                }
            }, {
                className: "flex-25",
                key: 'twitter_account_name',
                type: 'input',
                templateOptions: {
                    label: "Twitter Account Name"
                }
            }, {
                className: "flex-25",
                key: 'twitter_widget_id',
                type: 'input',
                templateOptions: {
                    label: "Twitter Widget ID"
                }
            }, {
                className: "flex-25",
                key: 'is_public',
                type: 'checkbox',
                templateOptions: {
                    label: 'Make Profile Public'
                }
            }];
        };

        var FilesForm = function (scope) {
            return [{
                "className": "flex-33",
                "key": "$logo",
                "type": "file_upload",
                "templateOptions": {
                    "label": "Upload Logo",
                    "displayable_picture": scope.farmProfile['displayable_logo'],
                    required: false
                }
            },
                {
                    "className": "flex-33",
                    "key": "$picture",
                    "type": "file_upload",
                    "templateOptions": {
                        "label": "Upload Picture",
                        "displayable_picture": scope.farmProfile['displayable_picture'],
                        required: false
                    }
                },
                {
                    "className": "flex-33",
                    "key": "$picture1",
                    "type": "file_upload",
                    "templateOptions": {
                        "label": "Upload Another Picture",
                        "displayable_picture": scope.farmProfile['displayable_picture1'],
                        required: false
                    }
                }];
        };

        var cleanData = function (obj) {
            for (var key in obj) {
                if (obj.hasOwnProperty(key) && !obj[key]) {
                    delete obj[key];
                }
            }
        }

        if (farm_slug) {
            $scope.farmProfile = FarmProfileFactory.get({farm_slug: farm_slug}, function (result) {
                $scope.farmProfile = result;
                delete $scope.farmProfile['logo'];
                delete $scope.farmProfile['picture'];
                delete $scope.farmProfile['picture1'];
                $scope.FarmProfileFilesForm = FilesForm($scope);
                $scope.dialogTitle = $filter('translate')('farmDetails.edit_farm_profile');
            });
        } else {
            $scope.farmProfile = {};
            $scope.farmProfile['displayable_logo'] = '';
            $scope.farmProfile['displayable_picture'] = '';
            $scope.farmProfile['displayable_picture1'] = '';
            $scope.dialogTitle = $filter('translate')('farmDetails.add_farm_profile');
            $scope.FarmProfileFilesForm = FilesForm($scope);
        }

        $scope.farm_dict = KhutilsFarmListFactory.query({"as_dict": "yes"}, function (result) {
            $scope.farm_dict = result;
            $scope.teamusers = TeamUsersFactory.query(function (result) {
                $scope.teamusers = result;
                FarmProfileFactory.query(function (result) {
                    $scope.farms_with_profile = result.map(function (farm) {
                        return farm.farm_slug;
                    });
                });
                $scope.FarmProfileForm = baseForm($scope);
            });
        });


        $scope.includePictures = function () {
            if ($scope.farmProfile['displayable_logo'] && typeof $scope.farmProfile['logo'] == 'string') {
                delete $scope.farmProfile['logo'];
            }
            if ($scope.farmProfile['displayable_picture'] && typeof $scope.farmProfile['picture'] == 'string') {
                delete $scope.farmProfile['picture'];
            }
            if ($scope.farmProfile['displayable_picture1'] && typeof $scope.farmProfile['picture1'] == 'string') {
                delete $scope.farmProfile['picture1'];
            }

        };

        $scope.ok = function () {
            $scope.includePictures();
            cleanData($scope.farmProfile);
            if (farm_slug) {
                FarmProfileFactory.update({farm_slug: farm_slug}, $scope.farmProfile, function (response) {
                    $khMessage.show($filter('translate')('updated_successfully'));
                    $mdDialog.hide(response);
                }, function (error) {
                    GenericUtilsService.handleError($filter, $khMessage, error);
                })
            } else {
                FarmProfileFactory.save($scope.farmProfile, function (result) {
                    $khMessage.show($filter('translate')('created_successfully'));
                    $mdDialog.hide(result);
                }, function (error) {
                    GenericUtilsService.handleError($filter, $khMessage, error);
                })
            }
        };

        $scope.cancel = function () {
            $mdDialog.cancel();
        };


        var descriptionForm = descriptionForm || {};
        var makeProfilePublicForm = makeProfilePublicForm || {};
    });
