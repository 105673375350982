(function (angular) {
    "use strict";
    angular.module('FmsApp')
        .factory('ActiveIngredientFactory', ActiveIngredientFactory);

    function ActiveIngredientFactory($resource) {
        return $resource(KhToolsBaseUrl + '/api/v1.0/products/activeingredients/', {}, {
            query: {
                isArray: true
            }
        });
    }

})(angular);