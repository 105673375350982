/**
 * Simple table to show the most slowest API calls
 */
angular.module('FmsApp').component('slowQueriesList', {
    templateUrl: '/static/assets/khapps/fms_app/admin/slow-queries-list.component.html',
    controller: function ($http) {
        var self = this;
        self.filter = {
            threshold: 10,
            from_date: moment().subtract(7, 'days').toDate()
        };

        self.refresh = function () {
            self.loading = true;
            var params = {
                min_time: self.filter.threshold,
                from_date: self.filter.from_date.toISOString()
            };
            $http.get('/api/admin/slow-requests/by-endpoint/', {params:params}).then(function (resp) {
                self.result = resp.data;
                self.loading = false;
            });
        };

        self.refresh();
    }
});
