import {merge} from 'rxjs';

angular.module('FmsApp')
    .controller('CropVarietyController', function CropVarietyController($scope,
                                                                        $rootScope,
                                                                        NgTableParams,
                                                                        $filter,
                                                                        $resource,
                                                                        TeamSelectionService,
                                                                        VarietyService,
                                                                        FMSSetUpHelperService) {
        $rootScope.page_id = 'farm';
        $rootScope.sub_app_page_id = 'farm_setup';
        $rootScope.currentNavItem = 'variety';
        $scope.showEmptyState = false;

        const sub = merge(TeamSelectionService.get$(), VarietyService.varietySaved$).subscribe(() => {
            $resource('/api/variety/report/').query().$promise.then( (varieties) => {
                $scope.varietyTable = new NgTableParams({}, {dataset: varieties});
                $scope.showEmptyState = (response.total_count === 0);
            });
        });

        $scope.$on('$destroy', function () {
         sub.unsubscribe();
        });

        $scope.cropVarietyListCard = [{
            card_title: $filter('translate')('farmSetup.crop_variety'),
            kh_card_content_template: '/static/assets/khapps/fms_app/partials/setup/variety/crop_variety_list.html',
            card_flex: 100,
            show_progress_bar: true
        }];

        $scope.cropVarietyTableFilters = {
            variety_name: FMSSetUpHelperService.getFilterDict($filter, 'variety_name', 'text', 'farmSetup.filter_by_variety'),
            genus_name: FMSSetUpHelperService.getFilterDict($filter, 'genus_name', 'text', 'farmSetup.filter_by_crop'),
        };



    });
