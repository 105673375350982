angular.module('FmsApp').factory("StoreFactory", ["$resource", function ($resource) {
    return $resource('/api/farm-management/store/:store_id/', {store_id: '@store_id'}, {
        save: {
            method: 'POST',
        },
        update: {
            method: 'PUT'
        },
        get: {
            method: 'GET'
        },
        query: {
            method: 'GET',
            isArray: true
        }
    });
}]);
