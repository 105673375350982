angular.module('FmsApp').controller("AddEditLabController",
    function ($scope, $mdDialog, $khMessage, $filter,
              LaboratoryFactory, LaboratoryTypesFactory, slug, CachingUtilsService, GenericUtilsService) {

        var cleanData = function (obj) {
            for (var key in obj) {
                if (obj.hasOwnProperty(key) && !obj[key]) {
                    delete obj[key];
                }
            }
        };

        var LaboratoryForm = function (types) {
            return [{
                className: "flex-100",
                "type": "input",
                "key": "laboratory_name",
                templateOptions: {
                    label: "Name",
                    placeholder: "Name of laboratory",
                    required: true
                }
            }, {
                className: "flex-100",
                key: 'type',
                type: "searchable_select",
                templateOptions: {
                    multiple: true,
                    optionsAttr: 'bs-options',
                    ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                    valueProp: 'value',
                    labelProp: 'name',
                    label: "Type",
                    placeholder: "Type of laboratory",
                    options: types
                }
            }, {
                className: "flex-50",
                "type": "input",
                "key": "phone",
                templateOptions: {
                    label: "Phone Number",
                    placeholder: "Phone number of laboratory"
                }
            }, {
                className: "flex-50",
                "type": "input",
                "key": "email",
                templateOptions: {
                    label: "Email ID",
                    placeholder: "username@example.com"
                }
            }, {
                className: "flex-100",
                "type": "input",
                "key": "url",
                templateOptions: {
                    label: "Website",
                    placeholder: "http://www.example.com"
                }
            }, {
                className: "flex-100",
                id: 'soil_analysis_textarea',
                "type": "textarea",
                "key": "address",
                templateOptions: {
                    label: "Address",
                    placeholder: "Postal address of laboratory",
                    rows: 3
                }
            }];
        };
        if (slug) {
            $scope.dialogTitle = $filter('translate')('soilAnalysis.edit_item') + ' ' + $filter('translate')('lab');
            $scope.soilAnalysisLab = LaboratoryFactory.get({laboratory_slug: slug},
                function (result) {
                    $scope.soilAnalysisLab = result;
                });
        } else {
            $scope.dialogTitle = $filter('translate')('soilAnalysis.add_item') + ' ' + $filter('translate')('lab');
            $scope.soilAnalysisLab = {};
        }

        $scope.lab_types = CachingUtilsService.addFactoryToCache(LaboratoryTypesFactory, 'lab_types', false, {as_dict: "yes"});
        $scope.LabForm = LaboratoryForm($scope.lab_types);
        $scope.soilAnalysisLab = {};

        $scope.sendData = function () {
            cleanData($scope.soilAnalysisLab);

            if ($scope.soilAnalysisLab.laboratory_slug) {
                LaboratoryFactory.update({laboratory_slug: $scope.soilAnalysisLab.laboratory_slug}, $scope.soilAnalysisLab, function (response) {
                    $khMessage.show($filter('translate')('common.update_success'), 3000);
                    $mdDialog.hide(response);
                }, function (error) {
                    GenericUtilsService.handleError($filter, $khMessage, error);
                })
            } else {
                LaboratoryFactory.save($scope.soilAnalysisLab, function (response) {
                    $khMessage.show($filter('translate')('common.create_success'), 3000);
                    $mdDialog.hide(response);
                }, function (error) {
                    GenericUtilsService.handleError($filter, $khMessage, error);
                })
            }
        };

    });
