angular.module('FmsApp').factory("SharingInventoryFactory", ["$resource", function ($resource) {
    return $resource('/api/v1.0/fms/myfarmsharing/', {}, {
        get: {
            method: 'GET'
        },
        query: {
            params: {growing_season: '@growing_season'},
            url: '/api/v1.0/fms/myfarmsharing/',
            isArray: false
        }
    });
}]);