angular.module('FmsApp').run(function ($http, $rootScope, $route, $location, $timeout, $window, $mdDialog,
                     PlatformTerminologyFactory,
                     DateTimeHelperFactory,
                     UserSettingsService, formlyConfig, FMSConstantsService) {
    $http.defaults.xsrfCookieName = 'csrftoken';
    $http.defaults.xsrfHeaderName = 'X-CSRFToken';

    $rootScope.in_app_add_button = '/static/assets/khapps/fms_app/partials/includes/farm_navbar_add_button.html';
    $rootScope.select_filters_template = '/static/assets/khapps/fms_app/partials/includes/fms_navbar_filters.html';
    $rootScope.in_app_links_template = '/static/assets/khapps/fms_app/partials/fms_in_app_links.html';
    $rootScope.view_as_template = '/static/assets/khapps/fms_app/partials/includes/fms_navbar_views.html';
    $rootScope.is_superuser = is_superuser;

    $rootScope.platformTerminology = {};
    $rootScope.platformTerminology.general = window.platform_terminology.General;
    $rootScope.platformTerminology.fms = window.platform_terminology.FMS;

    UserSettingsService.promise.then(function (result) {
        // legacy rootScope stuff - new stuff should just use the UserSettingsService directly
        $rootScope.user_settings = result;
        if (result.DEFAULT_USER_READABLE_DATE_FORMAT) {
            $rootScope.userSettingDateFormat = DateTimeHelperFactory.convertDateTimeFormatFromPythonToMoment(
                result.DEFAULT_USER_READABLE_DATE_FORMAT);
        } else {
            $rootScope.userSettingDateFormat = 'DD MMM, YYYY';
        }
        if (result.DEFAULT_USER_READABLE_TIME_FORMAT) {
            $rootScope.userSettingTimeFormat = DateTimeHelperFactory.convertDateTimeFormatFromPythonToMoment(
                result.DEFAULT_USER_READABLE_TIME_FORMAT);
        } else {
            $rootScope.userSettingTimeFormat = 'HH:mm';
        }
        if (result.DEFAULT_USER_READABLE_DATE_TIME_FORMAT) {
            $rootScope.userSettingDateTimeFormat = DateTimeHelperFactory.convertDateTimeFormatFromPythonToMoment(
                result.DEFAULT_USER_READABLE_DATE_TIME_FORMAT);
        } else {
            $rootScope.userSettingDateTimeFormat = 'DD MMM, YYYY HH:mm';
        }
    });

    // Object Notification subscription
    $rootScope.ObjectNotificationSubscription = function (object_key, object_slug, objectName) {
        $mdDialog.show({
            controller: 'UserNotificationSubscriptionController',
            templateUrl: '/static/assets/khapps/user_notification_preferences/UserNotificationSubscription.html',
            parent: angular.element(document.body),
            locals: {
                configDict: {
                    'object_key': object_key,
                    'object_slug': object_slug,
                    'name': objectName
                }
            },
            clickOutsideToClose: false
        });
    };

    //KH-datepicker for form
    formlyConfig.setType({
        name: 'khDatePicker',
        templateUrl: '/static/assets/khapps2/base/khformelements/partials/kh_datepicker.html'
    });

    $rootScope.get_this_location_on_map = function (type, slug) {
        var mapid = 'landingMap';
        $rootScope.changeView(FMSConstantsService.viewTypes().MAP_VIEW, mapid);

        switch (type) {
            case 'farm':
                $rootScope.zoom_to_selected_feature($rootScope, mapid, slug, '', $rootScope.farmdata_geoJsonLayer, FMSConstantsService.viewTypes().All,
                    $rootScope.farmdata_geoJson, 'farm_slug', 'farm_slug');
                break;
            case 'field':
                $rootScope.zoom_to_selected_feature($rootScope, mapid, slug, '', $rootScope.fielddata_geoJsonLayer, 'All Fields',
                    $rootScope.fielddata_geoJson, 'field_slug', 'farm_slug');
                break;
            case 'fieldplotcrop':
                $rootScope.zoom_to_selected_feature($rootScope, mapid, slug, '', $rootScope.plotdata_geoJsonLayer, FMSConstantsService.viewTypes().All,
                    $rootScope.plotdata_geoJson, 'plot_slug', 'plot_slug');
                break;
        }
    };
});
