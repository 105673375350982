angular.module('FmsApp').factory("PlantingSeasonsFactory", ["$resource", function ($resource) {
    return $resource('api/v1.0/khutils/plantingseasons/');
}]);

angular.module('FmsApp').factory("GrowingSeasonFarmFieldFilterFactory", ["$resource", function ($resource) {
    return $resource('/api/v1.0/khutils/growingseasonfarmfield/',
        {farm: '@farm', field: '@field', growing_season: '@growing_season'},
        {
            query: {
                isArray: false
            }
        });
}]);
angular.module('FmsApp').factory("GenericFertiliserProductFactory", ["$resource", function ($resource) {
    return $resource('/api/v1.0/genericproduct/fertiliser/:product_slug/');
}]);
angular.module('FmsApp').factory("GenericPesticideProductFactory", ["$resource", function ($resource) {
    return $resource('/api/v1.0/genericproduct/pesticide/:product_slug/');
}]);
angular.module('FmsApp').factory("GenericSeedProductFactory", ["$resource", function ($resource) {
    return $resource('/api/v1.0/genericproduct/seed/:product_slug/');
}]);
angular.module('FmsApp').factory("ProductTypesFactory", ["$resource", function ($resource) {
    return $resource('/api/v1.0/genericproduct/types/', {as_dict: 'yes'});
}]);
angular.module('FmsApp').factory("GenericSeedBatchFactory", ["$resource", function ($resource) {
    return $resource('/api/v1.0/genericproduct/genericbatch/seed/');
}]);
angular.module('FmsApp').factory("GenericPesticideBatchFactory", ["$resource", function ($resource) {
    return $resource('/api/v1.0/genericproduct/genericbatch/pesticide/');
}]);
angular.module('FmsApp').factory("GenericBurnDownBatchFactory", ["$resource", function ($resource) {
    return $resource('/api/v1.0/genericproduct/genericbatch/pesticide/');
}]);
angular.module('FmsApp').factory("GenericFertiliserBatchFactory", ["$resource", function ($resource) {
    return $resource('/api/v1.0/genericproduct/genericbatch/fertiliser/');
}]);
angular.module('FmsApp').factory("CurrentSeasonFactory", ["$resource", function ($resource) {
    return $resource('/api/v1.0/khutils/currentplantingseason/', {},
        {
            query: {
                method: 'GET',
                isArray: false
            }
        });
}]);