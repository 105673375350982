angular.module('FmsApp').controller("SoilAnalysisLandingController",
    function ($scope, $rootScope, $mdDialog, $filter, $khMessage, NgTableParams, SoilTestFactory,
              FMSGenericHelperService, GenericUtilsService) {

        $rootScope.currentNavItem = "results";
        $scope.showEmptyMessage = false;

        FMSGenericHelperService.initController($scope, $rootScope, $filter);
        FMSGenericHelperService.appSideBar("farm", "farm_soil");

        $scope.tableFilters = {
            sample_name: FMSGenericHelperService.getFilterDict($filter, 'soilResult', 'sample_name'),
            sampling_date: FMSGenericHelperService.getFilterDict($filter, 'soilResult', 'sampling_date'),
            sample_depth_value: FMSGenericHelperService.getFilterDict($filter, 'soilResult', 'sample_depth_value'),
            farm_field: FMSGenericHelperService.getFilterDict($filter, 'soilResult', 'farm_field'),
            lab: FMSGenericHelperService.getFilterDict($filter, 'soilResult', 'lab')
        };

        $rootScope.$watch("selected_team", function (newValue, oldValue) {
            if (oldValue != newValue) {
                $khMessage.show('Please wait. Updating data.');
                load_soilsample_table_data();
            }
        });

        function load_soilsample_table_data() {
            $scope.all_soil_tests = SoilTestFactory.query(function (result) {
                $scope.all_soil_tests = result;
                $scope.showEmptyMessage = (result.length === 0);
                $scope.dataAppsTableParameters = new NgTableParams({}, {dataset: $scope.all_soil_tests});
                $khMessage.hide()
            });
        }

        load_soilsample_table_data();

        $scope.SoilAnalysisCards = [
            {
                card_title: 'Soil Analysis Results',
                kh_card_content_template: '/static/assets/khapps/fms_app/partials/soilanalysis/soilanalysis_tests_table.html',
                kh_card_toolbar_filters_template: '',
                card_flex: 100,
                show_progress_bar: true
            }
        ];

        $scope.changeDataView = function (selectedDataView) {
            $scope.currentDataView = selectedDataView;
        };

        $scope.editSoilTest = function (slug) {
            $mdDialog.show({
                templateUrl: '/static/assets/khapps/fms_app/partials/soilanalysis/forms/soilresults_form_dialog.html',
                controller: 'AddEditSoilTestResultsController',
                locals: {
                    slug: slug
                }
            }).then(function (response) {
                if (typeof(response) !== 'undefined') {
                    $scope.all_soil_tests = GenericUtilsService.updateListAfterEdit($scope.all_soil_tests, "soil_test_slug", slug, response);
                    $scope.dataAppsTableParameters = new NgTableParams({}, {dataset: $scope.all_soil_tests});
                }
            });
        }
        $scope.viewSoilTest = function (testSlug) {
            $mdDialog.show({
                templateUrl: '/static/assets/khapps/fms_app/partials/soilanalysis/forms/soilresults_view_dialog.html',
                controller: 'ViewSoilTestResultsController',
                locals: {
                    slug: testSlug
                }
            })
        }

        $rootScope.$on("create_success", function (broadcast, response) {
            if (typeof(response) !== 'undefined') {
                $scope.all_soil_tests.unshift(response);
                $scope.dataAppsTableParameters = new NgTableParams({}, {dataset: $scope.all_soil_tests});
            }
        });


    });
