angular.module('FmsApp').service("PlantingProgressChartService", function () {

    lightColors = ['#7cb5ec', '#434348', '#90ed7d', '#f7a35c', '#8085e9', '#f15c80', '#e4d354', '#2b908f', '#f45b5b', '#91e8e1'];
    darkColors = ['#2483DF', '#111112', '#43E023', '#DF6C0A', '#2932DA', '#D31240', '#B4A21C', '#134242', '#DB0E0E', '#3CD6CA'];

    this.PlantingProgressChart = function (data) {
        return {
            chart: {
                zoomType: 'x'
            },
            title: {
                text: ''
            },
            yAxis: [{
                lineWidth: 1,
                title: {
                    text: 'Planted Area (' + data.area_unit + ')'
                }
            }, {
                lineWidth: 1,
                opposite: true,
                min: 0,
                max: 100,
                title: {
                    text: 'Percentage Area Planted'
                }
            }],
            xAxis: {
                lineWidth: 1,
                type: 'datetime',
                dateTimeLabelFormats: {
                    month: '%b %e, %Y'
                }
            },
            credits: {
                enabled: false
            },
            tooltip: {
                dateTimeLabelFormats: {
                    week: '%A, %b %e, %Y'
                }
            },
            plotOptions: {
                series: {
                    dataGrouping: {
                        dateTimeLabelFormats: {
                            week: ['%A, %b %e, %Y']
                        }
                    }
                }
            },
            legend: {
                enabled: true,
                align: 'center',
                verticalAlign: 'bottom',
                backgroundColor: 'white',
                borderColor: '#CCC',
                borderWidth: 1,
                shadow: false
            },
            series: getProgressData(data)
        };
    };

    function sortByTimeStamp(a, b) {
        if (a[0] < b[0]) return -1;
        if (a[0] > b[0]) return 1;
        return 0;
    }

    function getProgressData(data) {
        var colorChart = {};
        var seriesData = [];
        for (var key in data.area_chart_data) {
            if (data.area_chart_data.hasOwnProperty(key)) {
                colorChart[key] = getAColor();
                seriesData.push({
                    id: key,
                    name: key,
                    type: 'column',
                    color: colorChart[key],
                    data: data.area_chart_data[key].sort(sortByTimeStamp),
                    tooltip: {
                        valueSuffix: ' ' + data.area_unit
                    }
                });
            }
        }
        for (var key in data.percent_area_chart_data) {
            if (data.percent_area_chart_data.hasOwnProperty(key)) {
                seriesData.push({
                    linkedTo: key,
                    name: key,
                    type: 'spline',
                    yAxis: 1,
                    color: getDarkShadeOfAreaSeriesColor(seriesData, key),
                    data: data.percent_area_chart_data[key].sort(sortByTimeStamp),
                    marker: {
                        symbol: 'circle',
                        enabled: false
                    },
                    tooltip: {
                        valueSuffix: ' %'
                    }
                });
            }
        }

        return seriesData;
    };

    var colorCount = 0;

    getAColor = function () {
        if (colorCount < lightColors.length) {
            colorCount++;
            return lightColors[colorCount - 1];
        } else {
            colorCount = 1;
            return lightColors[0];
        }
    };

    function getDarkShadeOfAreaSeriesColor(data, key) {
        for (var i = 0; i < data.length; i++) {
            if (data[i].name === key) {
                return darkColors[lightColors.indexOf(data[i].color)];
            }
        }
    }
});
