angular.module('FmsApp').controller("FarmEquipmentListController",
    function ($rootScope, $scope, FarmEquipmentFactory, NgTableParams, $mdDialog, $filter, GenericUtilsService) {

        $rootScope.page_id = "farm";
        $rootScope.sub_app_page_id = "farm_profile";
        $rootScope.currentNavItem = "equipment";

        $scope.showEmptyState = false;
        function loadFarmEqiupment() {
            $scope.all_farm_equipments = FarmEquipmentFactory.query(function (result) {
                $scope.all_farm_equipments = result;
                $scope.showEmptyState = (result.length == 0);
                $scope.equipmentsTable = new NgTableParams({}, {dataset: result});
            });
        }

        loadFarmEqiupment();

        $scope.farmEquipmentsListCard = [{
            card_title: $filter('translate')('farmDetails.farm_equipment'),
            kh_card_content_template: '/static/assets/khapps/fms_app/partials/farmdetails/equipment/farm_equipment_list.html',
            card_flex: 100,
            show_progress_bar: true
        }];

        $scope.editFarmEquipment = function (equipment_slug) {
            $mdDialog.show({
                controller: 'AddEditFarmEquipmentController',
                templateUrl: '/static/assets/khapps/fms_app/partials/farmdetails/equipment/add_edit_farm_equipment_dialog_form.html',
                locals: {
                    equipment_slug: equipment_slug
                }
            }).then(function (response) {
                $scope.all_farm_equipments = GenericUtilsService.updateListAfterEdit($scope.all_farm_equipments, "equipment_slug", equipment_slug, response);
                $scope.equipmentsTable = new NgTableParams({}, {dataset: $scope.all_farm_equipments});
            })
        };

        $rootScope.$on("farmEquipmentAdded", function (boradcast, response) {
            $scope.all_farm_equipments.unshift(response);
            $scope.equipmentsTable = new NgTableParams({}, {dataset: $scope.all_farm_equipments});
            $scope.showEmptyState = false;
        });

        $scope.$on("team_changed", function () {
            loadFarmEqiupment();
        });

        $scope.filterByEquipmentName = {
            equipment_name: {
                id: 'text',
                placeholder: $filter('translate')('farmDetails.filter_by_name')
            }
        };

        $scope.filterByModel = {
            model: {
                id: 'text',
                placeholder: $filter('translate')('farmDetails.filter_by_model')
            }
        };

        $scope.filterByType = {
            type: {
                id: 'text',
                placeholder: $filter('translate')('farmDetails.filter_by_type')
            }
        };

        $scope.filterByRegistrationNumber = {
            registration_number: {
                id: 'number',
                placeholder: $filter('translate')('farmDetails.filter_by_registration_number')
            }
        };

    });
