angular.module('FmsApp').service('FieldBoundaryDialogService', function ($mdDialog, $http, $timeout,
                                                                         $resource,
                                                                         ErrorLoggingService,
                                                                         MyFarmMapService,
                                                                         FarmFiltersService) {


    /**
     * Show the shapefile import dialog
     * @returns {promise} containing new fields if successful
     */
    this.show = function () {
        return $mdDialog.show({

            templateUrl: '/static/assets/khapps/fms_app/controllers/file_upload/upload-field-boundary.dialog.html',
            controller: function ($scope) {
                $scope.uploaded = false;

                /**
                 * Show a simple error message
                 */
                function showErrorMessage(resp) {

                    ErrorLoggingService.logErrorResponse(resp);

                    if (!$scope.cancelled) {
                        $mdDialog.hide();
                        $mdDialog.show(
                            $mdDialog.alert()
                                .clickOutsideToClose(true)
                                .title('Shapefile upload failed')
                                .textContent('Failed to validate this shapefile: please check that it is a valid ESRI format')
                                .ok('OK'));
                    }
                }

                function statusPoll(id) {
                    $timeout( ()=> {
                        $resource('/api/field/shapefile/import/:id/status/').get({id:id}).$promise.then(
                            (ret) => {
                                $scope.status = ret;
                                $scope.completed = (ret.status === 'completed');
                                if (!$scope.completed && !$scope.cancelled) {
                                    if (ret.total && ret.field_ids) {
                                        $scope.progress = 100 * ret.field_ids.length / ret.total;
                                        statusPoll(id);
                                    }
                                } else {
                                    // completed - update the filters and map
                                    $scope.importing = false;
                                    FarmFiltersService.reload().then(()=> {
                                       MyFarmMapService.reload(FarmFiltersService.filter, true, true);
                                    });
                                }
                            }
                        );
                    }, 2000);
                }

                $scope.send = () => {
                    let fd = new FormData();
                    fd.append('file', $scope.file);
                    // first we upload the file
                    $scope.uploading = true;
                    $http.post('/api/field/shapefile/upload/', fd, {
                        headers: {'Content-Type': undefined}
                    }).then((resp) => {
                        // now ask for the feature property name to use as the field name, and the
                        // destination farm
                        $scope.uploading = false;
                        $scope.uploaded = true;
                        let data = resp.data;
                        $scope.upload = data;
                        let options = $scope.options = {};
                        if ($scope.upload.names.length) {
                            $scope.options.name = $scope.upload.names[0];
                        }
                        // technically the FarmFilterService is async but you're really very unlikely to
                        // get this far and not have the farms loaded yet
                        $scope.farms = FarmFiltersService.getFarms();
                        if ($scope.farms.length) {
                            $scope.options.farm = $scope.farms[0];
                        }
                        $scope.import = () => {
                            $scope.importing = true;
                            // now import to the named Farm using the selected name property
                            $http.post(`/api/field/shapefile/import/${options.farm.id}/${data.id}/`,
                                {
                                    name_property: $scope.options.name
                                })
                                .then(()=> {
                                    // kick off the status poll
                                    $scope.progress = 0;
                                    statusPoll(data.id);
                                }, showErrorMessage);
                        };
                    }, showErrorMessage);

                };
                $scope.ok = () => {
                    $mdDialog.hide();
                }

                $scope.cancel = () => {
                    $scope.cancelled = true;
                    $mdDialog.hide();
                };
            }
        });
    };
});
