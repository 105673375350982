angular.module('FmsApp').controller("AddEditFarmEquipmentController",
    function ($scope, KhutilsFarmListFactory, UnitsFactory, FarmEquipmentFactory, TypesOfFarmEquipmentFactory, $mdDialog,
              equipment_slug, $khMessage, $filter, TeamsOfUserFactory, FarmEquipmentFormService, CachingUtilsService, GenericUtilsService) {

        if (equipment_slug) {
            $scope.dialogTitle = $filter('translate')('farmDetails.edit_farm_equipment');
            $scope.farmEquipment = FarmEquipmentFactory.get({equipment_slug: equipment_slug},
                function (result) {
                    $scope.farmEquipment = result;
                });
        } else {
            $scope.dialogTitle = $filter('translate')('farmDetails.add_farm_equipment');
            $scope.farmEquipment = {};
        }

        $scope.farm_equipment_types_dict = CachingUtilsService.addFactoryToCache(TypesOfFarmEquipmentFactory, "farm_equipment_types", null, {as_dict: "yes"});

        $scope.equipment_units = UnitsFactory.get({measure_of: "Volume,Power"}, function (result) {
            $scope.equipment_units = result;
            $scope.teams = TeamsOfUserFactory.query({as_dict: "yes"}, function (result) {
                $scope.teams = result;
                $scope.FarmEquipmentForm = FarmEquipmentFormService.farmEquipmentForm($scope);
            })
        });

        $scope.sendData = function () {
            if (equipment_slug) {
                FarmEquipmentFactory.update({equipment_slug: equipment_slug}, $scope.farmEquipment, function (response) {
                    $khMessage.show($filter('translate')('updated_successfully'), 5000);
                    $mdDialog.hide(response);
                }, function (error) {
                    GenericUtilsService.handleError($filter, $khMessage, error);
                })
            } else {
                FarmEquipmentFactory.save($scope.farmEquipment, function (response) {
                    $khMessage.show($filter('translate')('created_successfully'), 5000);
                    $mdDialog.hide(response);
                }, function (error) {
                    GenericUtilsService.handleError($filter, $khMessage, error);
                })
            }
        };

    });
