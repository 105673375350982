angular.module("EventsApp").controller("EventsNavbarFiltersController", ["$scope", "$mdDialog", "$rootScope",
    function ($scope, $mdDialog, $rootScope) {
        $scope.regions = [
            {
                name: "National",
                value: "National"
            },
            {
                name: "North",
                value: "North"
            },
            {
                name: "East",
                value: "East"
            },
            {
                name: "West",
                value: "West"
            },
            {
                name: "South",
                value: "South"
            }
        ];
        const currentYear = new Date().getFullYear();
        let yearList = [];
        for (let i = 2016; i <= currentYear + 1; i++) {
            yearList.push({
                name: i,
                value: i,
            });
        }
        $scope.year_list = yearList;
        ///////////////////////////////////////////////////////////////////////////////////////////////////////////////
        ////////////////////////////////////////////////   Filters     ////////////////////////////////////////////////
        ///////////////////////////////////////////////////////////////////////////////////////////////////////////////

        $scope.regionFilterBroadcast = function (item) {
            $rootScope.$broadcast("region_filtered", item);
        };
        $scope.yearFilterBroadcast = function (item) {
            $rootScope.$broadcast("selected_year", item);
        };
        $rootScope.$on("region_filtered_clear", function (ev) {
            $scope.selected_region = null;
        });
        $rootScope.$on("year_filtered_clear", function (ev) {
            $scope.selected_year = null;
        });
    }]);
