angular.module('FmsApp').controller("AddEditWaterSourceController",
    function ($scope, KhutilsFarmListFactory, WaterSourceFactory, $khMessage, TypesOfWaterSourceFactory, $mdDialog,
              source_slug, $filter, TeamsOfUserFactory, WaterSourceFormService, GenericUtilsService) {

        $scope.water_source_types_dict = TypesOfWaterSourceFactory.query({as_dict: "yes"}, function (result) {
            $scope.water_source_types_dict = result;
            $scope.teams = TeamsOfUserFactory.query({as_dict: "yes"}, function (teams) {
                $scope.teams = teams;
                $scope.WaterSourceForm = WaterSourceFormService.waterSourceForm($scope);
            });
        });

        $scope.sendData = function () {
            if (source_slug) {
                WaterSourceFactory.update({source_slug: source_slug}, $scope.waterSource, function (response) {
                    $khMessage.show($filter('translate')('updated_successfully'), 5000);
                    $mdDialog.hide(response);
                }, function (error) {
                    GenericUtilsService.handleError($filter, $khMessage, error);
                })
            } else {
                WaterSourceFactory.save($scope.waterSource, function (response) {
                    $khMessage.show($filter('translate')('created_successfully'), 5000);
                    $mdDialog.hide(response);
                }, function (error) {
                    GenericUtilsService.handleError($filter, $khMessage, error);
                })
            }
        };

        if (source_slug) {
            $scope.dialogTitle = $filter('translate')('farmDetails.edit_water_source');
            $scope.waterSource = WaterSourceFactory.get({source_slug: source_slug},
                function (result) {
                    $scope.waterSource = result;
                }, function (error) {
                    $khMessage.show($filter('translate')('default_error_message'));
                });
        } else {
            $scope.dialogTitle = $filter('translate')('farmDetails.add_water_source');
            $scope.waterSource = {};
        }

    });
