angular.module('FmsApp').factory("EmergencyContactFactory", ["$resource", function ($resource) {
    return $resource('api/v1.0/farmdetails/emergencycontact/:farm_slug/', {farm_slug: '@farm_slug'},
        {
            save: {
                method: 'POST'
            },
            update: {
                method: 'PUT'
            },
            get: {
                method: 'GET'
            }
        });
}]);