angular.module("EventsApp").config(["$routeProvider", "$resourceProvider", "$translateProvider",
    function ($routeProvider, $resourceProvider, $translateProvider) {
        $translateProvider.translations('en', {
            details: 'Details',
            delete: 'Delete',
            training_events: 'Training events',
            open_events: 'Open events',
            member_events: 'Member events',
            region: 'Region',
            year: 'Year',
            clear: 'Clear'
        });
        $resourceProvider.defaults.stripTrailingSlashes = false;
        $routeProvider
            .when("/", {
                name: 'Member',
                controller: "UpcomingEventsController",
                templateUrl: "/static/assets/khapps/events/partials/member_events.html"
            })
            .when("/training", {
                name: 'Training',
                controller: "TrainingEventsController",
                templateUrl: "/static/assets/khapps/events/partials/training_events.html"
            })
            .when("/open", {
                name: 'Open',
                controller: "OpenEventsController",
                templateUrl: "/static/assets/khapps/events/partials/open_events.html"
            })
            .otherwise({
                redirectTo: '/'
            });
    }
]);